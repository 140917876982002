import { MODULE_ICON } from "shared/constants/module-icon.constants";
import {
  ASSIGNMENT_PATHS,
  AUTOMATION_PATHS,
  CONTENT_LIBRARY_PATHS,
  DASHBOARD_PATHS,
  EVENTS_PATHS,
  HIRED_PATHS,
  JOB_PATHS,
  PARTICIPANT_PATHS,
  PARTNER_PATHS,
  PIPELINE_PATHS,
  SHORTLIST_PATHS,
  SURVEYS_PATHS,
  TAGS_PATHS,
  TEAM_PATHS,
} from "team/constants/paths.constants";

export function getNavItems(roles: string[], isUtahParticipant: boolean = false, canAccessUtahFeatures = false) {
  const isAdmin = roles.includes("admin");

  return {
    breakline: [
      {
        label: "Dashboard",
        icon: MODULE_ICON.home,
        to: DASHBOARD_PATHS.root,
        end: true,
      },
      {
        icon: MODULE_ICON.pipelinePlus,
        label: "Pipeline +",
        to: PIPELINE_PATHS.root,
        end: false,
      },
      {
        label: "Participants",
        icon: MODULE_ICON.participants,
        to: PARTICIPANT_PATHS.root,
        end: false,
      },
      {
        label: "Partners",
        icon: MODULE_ICON.partners,
        to: PARTNER_PATHS.root,
        end: false,
      },
      {
        label: "Roles",
        icon: MODULE_ICON.roles,
        to: JOB_PATHS.root,
        end: false,
      },
      {
        label: "Create Shortlist",
        icon: MODULE_ICON.shortlist,
        to: SHORTLIST_PATHS.root,
        end: false,
      },
      {
        label: "Hired",
        icon: MODULE_ICON.hired,
        to: HIRED_PATHS.root,
        end: false,
      },
      {
        label: "Resources",
        icon: MODULE_ICON.resources,
        to: CONTENT_LIBRARY_PATHS.root,
        end: false,
      },
      {
        label: "Assignments",
        icon: MODULE_ICON.assignments,
        to: ASSIGNMENT_PATHS.root,
        end: false,
      },
      {
        label: "Surveys",
        icon: MODULE_ICON.surveys,
        to: SURVEYS_PATHS.root,
        end: false,
      },
      {
        label: "Events",
        icon: MODULE_ICON.events,
        to: EVENTS_PATHS.root,
        end: false,
      },
      {
        label: "Automation Hub",
        icon: MODULE_ICON.automation,
        to: AUTOMATION_PATHS.root,
        end: false,
      },
      ...(isAdmin
        ? [
            {
              label: "Tags",
              icon: MODULE_ICON.tags,
              to: TAGS_PATHS.root,
              end: false,
            },
            {
              label: "Team",
              icon: MODULE_ICON.team,
              to: TEAM_PATHS.root,
              end: false,
            },
          ]
        : []),
    ],
    participant: [
      {
        label: "Dashboard",
        icon: MODULE_ICON.home,
        to: "/",
        end: true,
      },
      {
        label: "Companies",
        icon: "building-line",
        to: "/companies",
        end: false,
      },
      {
        label: "Roles",
        icon: MODULE_ICON.roles,
        to: "/jobs",
        end: false,
      },
      ...(isUtahParticipant && canAccessUtahFeatures
        ? [
            {
              label: "Saved",
              icon: MODULE_ICON.saved,
              to: "/saved",
              end: false,
            },
          ]
        : []),
      {
        label: "Interviews",
        icon: "question-answer-line",
        to: "/interviews",
        end: false,
      },
      ...(!isUtahParticipant
        ? [
            {
              label: "Game Plans",
              icon: MODULE_ICON.gameplans,
              to: "/game-plans",
              end: false,
            },
          ]
        : []),
      {
        label: "Resources",
        icon: MODULE_ICON.resources,
        to: "/content-library",
        end: false,
      },
      {
        label: "Assignments",
        icon: MODULE_ICON.assignments,
        to: "/assignments",
        end: false,
      },
      {
        label: "Events",
        icon: MODULE_ICON.events,
        to: "/events",
        end: false,
      },
    ],
  } as const;
}
