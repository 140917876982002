import * as PopoverPrimitive from "@radix-ui/react-popover";
import React from "react";
import { twMerge } from "tailwind-merge";

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

interface PopoverContentProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  container?: PopoverPrimitive.PopoverPortalProps["container"];
}
export const PopoverContent = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, PopoverContentProps>(
  ({ className, align = "start", container, sideOffset = 8, ...rest }, ref) => {
    const containerFromContext = React.useContext(PopoverContainerContext) ?? container;

    return (
      <PopoverPrimitive.Portal container={containerFromContext}>
        <PopoverPrimitive.Content
          {...rest}
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={twMerge(
            "border-ds-stroke-tertiary bg-ds-bg-foundation text-ds-text-primary data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-72 rounded-md border shadow outline-none",
            className,
          )}
        />
      </PopoverPrimitive.Portal>
    );
  },
);
PopoverContent.displayName = "PopoverContent";

/**
 * Provides a container for the Popover content to render into. It is particularly useful when the Popover is used
 * within a modal or dialog, as these can often interfere with the pointer events and scrolling behavior of the Popover
 * content. By providing a container, usually the modal or dialog itself, the Popover content can be rendered inside of
 * it, ensuring that it behaves as expected.
 *
 * By default, the container is `document.body`. However, this can be overridden by passing a different container prop.
 */
export function PopoverContainerProvider({
  children,
  container,
}: {
  children: React.ReactNode;

  container?: PopoverPrimitive.PopoverPortalProps["container"];
}) {
  return <PopoverContainerContext.Provider value={container}>{children}</PopoverContainerContext.Provider>;
}

const PopoverContainerContext = React.createContext<PopoverPrimitive.PopoverPortalProps["container"]>(undefined);
