export const ERROR_CODES = {
  BAD_REQUEST: "BAD_REQUEST",
  CONFLICT: "CONFLICT",
  NETWORK_ERROR: "NETWORK_ERROR",
  NOT_FOUND: "NOT_FOUND",
  RATE_LIMITED: "RATE_LIMITED",
  SERVER_ERROR: "SERVER_ERROR",
  TEAPOT: "TEAPOT",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  INVALID_ERROR: "INVALID_ERROR",
  REQUEST_CANCELLED: "REQUEST_CANCELLED",
} as const;

interface ApiErrorDetails {
  code: keyof typeof ERROR_CODES;
  status?: number;
  originalError: unknown;
  response?: {
    data?: unknown;
    headers?: Record<string, string>;
  };
  request?: {
    url?: string;
    method?: string;
    headers?: Record<string, string>;
    data?: unknown;
  };
}

export class ApiError extends Error {
  constructor(
    message: string,
    public details: ApiErrorDetails,
  ) {
    super(message);
    this.name = "ApiError";
    // Maintains proper stack trace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

export function createApiError(message: string, error: any, code: keyof typeof ERROR_CODES, status?: number) {
  const details: ApiErrorDetails = {
    code,
    status,
    originalError: error,
    response: error.response
      ? {
          data: error.response.data,
          headers: error.response.headers,
        }
      : undefined,
    request: error.config
      ? {
          url: error.config.url,
          method: error.config.method,
          headers: error.config.headers,
          data: error.config.data,
        }
      : undefined,
  };
  return new ApiError(message, details);
}
