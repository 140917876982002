import React from "react";
import { Link } from "react-router-dom";

if (process.env.NODE_ENV !== "production" && !process.env.REACT_APP_SITE_URL) {
  // eslint-disable-next-line
  console.error("InlineLink.js: No SITE_URL set in .env");
}

interface InlineLinkProps extends React.ComponentPropsWithoutRef<"a"> {
  href: string;
  children: React.ReactNode;
  target?: string;
  forceNewTab?: boolean;
}
const InlineLink = React.forwardRef<HTMLAnchorElement, InlineLinkProps>(
  ({ href, target = "_blank", forceNewTab, children, ...rest }, ref) => {
    const siteUrl = new RegExp(`https?://(www.)?${process.env.REACT_APP_SITE_URL}/?`, "g");

    if (!href) return null;
    const formatInternal = href.replace(siteUrl, "/");

    return formatInternal.match(/^(https?:)?\/\/|mailto:|tel:/) || forceNewTab ? (
      <a
        href={formatInternal}
        target={target}
        rel={target === "_blank" ? "noreferrer noopener" : undefined}
        ref={ref}
        {...rest}
      >
        {children}
      </a>
    ) : (
      <Link to={formatInternal} ref={ref} {...rest}>
        {children}
      </Link>
    );
  },
);

export default InlineLink;
