import { zodResolver } from "@hookform/resolvers/zod";
import { InfiniteData, useMutation, useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { RankSelectFormField } from "participant/components/RankFormField";
import { SelectPayTypeFormField } from "participant/components/SelectPayTypeFormField";
import { usePostHog } from "posthog-js/react";
import * as React from "react";
import { useForm } from "react-hook-form";
import api from "shared/api/api";
import { JOBS_QUERY_OPTIONS } from "shared/api/jobs";
import { PARTICIPANT_KEYS, SaveRoleBody } from "shared/api/participants";
import { PARTNER_QUERY_OPTIONS } from "shared/api/partners";
import { USER_OPTIONS } from "shared/api/user/user";
import { AsyncComboboxField } from "shared/components/ComboboxField/AsyncComboboxField";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "shared/components/ds/Form";
import { Input } from "shared/components/ds/Input";
import { Switch } from "shared/components/ds/Switch";
import { Textarea } from "shared/components/ds/Textarea";
import { SAVED_ROLES_EVENTS } from "shared/constants/events.constants";
import { createInfiniteDataTransform } from "shared/util/createInfiniteDataTransform";
import { useSWRConfig } from "swr";
import { z } from "zod";

export const SAVE_ROLE_FORM_ID = "save-role-form";
export function SaveRoleForm({
  children,
  onSaveSuccess,
  onSaveFailure,
}: {
  children?: ((isSubmitting: boolean) => React.ReactNode) | React.ReactNode;
  onSaveSuccess?: (data: SaveRoleFormOutput) => void;
  onSaveFailure?: () => void;
}) {
  const postHog = usePostHog();
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const queryClient = useQueryClient();
  const { mutate } = useSWRConfig();
  const saveRoleMutation = useMutation({
    mutationFn: async (data: SaveRoleBody) => {
      postHog.capture(SAVED_ROLES_EVENTS.SAVE_ROLE_CREATE_INITIATED);

      const res = await api.post(`/participants/${user.id}/saved/roles`, data);

      return res.data;
    },
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: [...PARTICIPANT_KEYS.detail(user.id.toFixed()), "savedRoles"],
        }),
        mutate(checkJobKey),
      ]);
    },
  });
  const form = useForm<SaveRoleFormInput>({
    mode: "onSubmit",
    resolver: zodResolver(SaveRoleFormSchema),
    shouldUnregister: true,
    defaultValues: {
      compensation: 0,
      notes: "",
      url: "",
    },
  });
  const { handleSubmit, control, watch, resetField } = form;
  const company = watch("company");
  const job = watch("job");
  const isKnownCompany = company?.type === "internal";
  const isKnownJob = job?.type === "internal";
  const jobsQueryOptions = React.useMemo(() => getJobsListQueryOptions(Number(company?.id)), [company?.id]);

  return (
    <>
      <Form {...form}>
        <form
          id={SAVE_ROLE_FORM_ID}
          onSubmit={handleSubmit((data) => {
            const newSavedRole = data as unknown as SaveRoleFormOutput;
            saveRoleMutation.mutate(newSavedRole, {
              onSuccess: () => {
                onSaveSuccess?.(newSavedRole);
              },
              onError: () => {
                onSaveFailure?.();
              },
            });
          })}
        >
          <div className="flex flex-col gap-6">
            <div className="flex w-full flex-wrap gap-x-6 gap-y-6">
              <FormField
                control={control}
                name="company"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel>Company</FormLabel>
                      <FormControl>
                        <AsyncComboboxField
                          {...field}
                          displayName="Company"
                          infiniteQueryOptions={getCompanyOptions}
                          enableCreate={{
                            getSelectionValue: (name) => ({
                              id: getRandomIntId(),
                              name,
                              type: "external",
                            }),
                          }}
                          onComboSelect={() => {
                            resetField("job", {
                              keepTouched: false,
                              keepDirty: false,
                              keepError: false,
                            });
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              {isKnownCompany ? (
                <FormField
                  control={control}
                  name="job"
                  render={({ field }) => {
                    return (
                      <FormItem className="w-full min-w-[280px] flex-1">
                        <FormLabel>Role</FormLabel>
                        <FormControl>
                          <AsyncComboboxField
                            {...field}
                            displayName="Role"
                            infiniteQueryOptions={jobsQueryOptions}
                            enableCreate={{
                              getSelectionValue: (name) => ({
                                id: getRandomIntId(),
                                name,
                                type: "external",
                              }),
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              ) : (
                <FormField
                  control={control}
                  name="job"
                  render={({ field: { value, onChange, ...rest } }) => {
                    return (
                      <FormItem className="w-full min-w-[280px] flex-1">
                        <FormLabel>Role (select a posted role)</FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            value={value?.name ?? ""}
                            placeholder="Software Engineer"
                            onChange={(e) => {
                              onChange({
                                id: getRandomIntId(),
                                name: e.target.value,
                                type: "external",
                              });
                            }}
                            {...rest}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              )}
            </div>
            <div className="flex w-full flex-wrap gap-x-6 gap-y-6">
              <FormField
                control={control}
                name="rank"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel optional>Rank</FormLabel>
                      <RankSelectFormField {...field} />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="compensation"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel optional>Compensation</FormLabel>
                      <div className="flex items-center gap-3">
                        <div className="w-full flex-1">
                          <FormControl>
                            <Input
                              {...field}
                              type="number"
                              prefix={<span>$</span>}
                              prefixStyles
                              step=".01"
                              placeholder="0"
                            />
                          </FormControl>
                        </div>
                        {field.value != null && (
                          <div className="w-auto flex-shrink-0">
                            <FormField
                              control={control}
                              name="payType"
                              render={({ field }) => {
                                return <SelectPayTypeFormField {...field} />;
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  );
                }}
              />
            </div>
            <FormField
              control={control}
              name="notes"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel optional>Notes</FormLabel>
                    <div
                      className="grow-wrap after:px-3 after:py-2 after:content-[attr(data-content)_'_']"
                      data-content={field.value}
                    >
                      <FormControl>
                        <Textarea className="" {...field} placeholder="Add notes..." />
                      </FormControl>
                    </div>
                  </FormItem>
                );
              }}
            />

            {job != null && !isKnownJob && (
              <>
                <FormField
                  control={control}
                  name="url"
                  render={({ field }) => {
                    return (
                      <FormItem className="w-full min-w-[280px] flex-1">
                        <FormLabel>Job Posting URL</FormLabel>
                        <FormControl>
                          <Input type="text" placeholder="https://www.example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
                <div className="self-start">
                  <FormField
                    control={control}
                    name="isRemote"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <FormItem className="flex-row gap-2">
                          <FormControl>
                            <Switch checked={value} onCheckedChange={onChange} />
                          </FormControl>
                          <FormLabel>Is this role remote?</FormLabel>
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </Form>
      {typeof children === "function" ? children(saveRoleMutation.isPending) : children}
    </>
  );
}

const SaveRoleFormSchema = z.object({
  isRemote: z.boolean().optional(),
  url: z.string().url().or(z.literal("")).optional(),
  company: z
    .object(
      {
        id: z.coerce.number(),
        name: z.string(),
        type: z.union([z.literal("internal"), z.literal("external")]),
      },
      {
        errorMap: () => ({
          message: "Please select a company",
        }),
      },
    )
    .transform((value) => {
      if (value.type === "internal") return Number(value.id);

      return value.name;
    }),
  job: z
    .object(
      {
        id: z.coerce.number(),
        name: z.string(),
        type: z.union([z.literal("internal"), z.literal("external")]),
      },
      {
        errorMap: () => ({
          message: "Please select a job",
        }),
      },
    )
    .transform((value) => {
      if (value.type === "internal") return Number(value.id);

      return value.name;
    }), // TODO: mayyybe want to render a `text` field here instead if it's an external company
  compensation: z.coerce
    .number({
      errorMap: () => ({
        message: "Please enter a compensation amount",
      }),
    })
    .optional(),
  payType: z
    .enum(["Salary", "Hourly"], {
      errorMap: () => ({
        message: "Please select a pay type",
      }),
    })
    .optional(),
  notes: z.string().optional(),
  rank: z
    .enum(["None", "Low", "Medium", "High"], {
      errorMap: () => ({
        message: "Select a rank",
      }),
    })
    .transform((value) => {
      if (value === "None") return;
      return value;
    })
    .optional(),
});
type SaveRoleFormInput = z.input<typeof SaveRoleFormSchema>;
type SaveRoleFormOutput = z.output<typeof SaveRoleFormSchema>;

// add "type" to company item schema
//  - this is because we want to parse for POST (id if internal, just string value if external)
// use uuid (I think) for id of generated item
// append to list of possible values and account for it in search by name (needs to be sync w/ fuzzy matching)

const checkJobKey = (args: string | [string, unknown]) => {
  if (typeof args === "string") return false;

  const [key] = args;

  if (key.startsWith("jobs")) {
    return true;
  }
};

function getCompanyOptions(params: { [key: string]: unknown }) {
  return {
    ...PARTNER_QUERY_OPTIONS.infinitePartnersList({
      ...params,
      filter: "jobs",
    }),
    select: (
      data: InfiniteData<
        {
          items: {
            id: number;
            name: string;
          }[];
        },
        number
      >,
    ) => {
      return createInfiniteDataTransform(data, (item) => {
        return {
          ...item,
          type: "internal",
        };
      });
    },
  };
}

function getJobsListQueryOptions(partnerId?: number) {
  return (params: { [key: string]: unknown }) => {
    return {
      ...JOBS_QUERY_OPTIONS.infiniteJobsList({
        ...params,
        ...(partnerId ? { partner: partnerId } : {}),
      }),
      select: (
        data: InfiniteData<
          {
            items: {
              id: number;
              title: string;
            }[];
          },
          number
        >,
      ) => {
        return createInfiniteDataTransform(data, (item) => {
          return {
            ...item,
            type: "internal",
            name: item.title,
          };
        });
      },
    };
  };
}

function getRandomIntId() {
  return Math.floor(Math.random() * Date.now());
}
