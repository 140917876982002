import type { CognitoRefreshToken, CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import axios from "axios";
import { queryClient } from "shared/api/query-client";
import { USER_OPTIONS } from "shared/api/user/user";
import { getImpersonation } from "shared/api/user/user.helpers";
import { createApiError, ERROR_CODES } from "shared/lib/api-error";
import config from "../../config";

export const baseURL = config.api;

const api = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

async function authenticationTokenRefresh() {
  try {
    // measure performance
    const session = await Auth.currentSession();

    if (isAccessTokenExpired(session)) {
      const refreshToken = session.getRefreshToken();
      const refreshedSession = await refreshSession(refreshToken);
      const newToken = refreshedSession.getAccessToken().getJwtToken();
      queryClient.invalidateQueries({ queryKey: USER_OPTIONS.user().queryKey });

      // Return the new token
      return newToken;
    }

    // Return the existing token
    return session.getAccessToken().getJwtToken();
  } catch (_err) {
    // If the user is not authenticated, return null
    queryClient.removeQueries({ queryKey: USER_OPTIONS.user().queryKey });
    return null;
  }
}

// This interceptor intercepts API responses and refreshes the user's access token if necessary
api.interceptors.request.use(async (request) => {
  try {
    const token = await authenticationTokenRefresh();
    request.headers.Authorization = `Bearer ${token}`;

    let impersonation;

    if (!impersonation && typeof window !== "undefined") {
      impersonation = getImpersonation();
    }

    if (impersonation?.username) {
      request.headers["X-BL-Impersonate"] = impersonation.username;
    }

    return request;
  } catch (err) {
    return request;
  }
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Ensure error is an object
    if (!error || typeof error !== "object") {
      return Promise.reject(createApiError("An invalid error was thrown", error, ERROR_CODES.INVALID_ERROR));
    }

    if (error.__CANCEL__) {
      return Promise.reject(createApiError("Request was cancelled", error, ERROR_CODES.REQUEST_CANCELLED));
    }

    if (!error.response) {
      return Promise.reject(createApiError("Network error occurred", error, ERROR_CODES.NETWORK_ERROR));
    }

    const { status } = error.response;

    switch (status) {
      case 400:
        return Promise.reject(createApiError("Bad request", error, ERROR_CODES.BAD_REQUEST, status));
      case 401:
      case 403:
        return Promise.reject(createApiError("Unauthorized", error, ERROR_CODES.UNAUTHORIZED, status));
      case 404:
        return Promise.reject(createApiError("Resource not found", error, ERROR_CODES.NOT_FOUND, status));
      case 409:
        return Promise.reject(createApiError("Conflict with existing data", error, ERROR_CODES.CONFLICT, status));
      case 418:
        return Promise.reject(createApiError("Server is a teapot", error, ERROR_CODES.TEAPOT, status));
      case 429:
        return Promise.reject(createApiError("Too many requests", error, ERROR_CODES.RATE_LIMITED, status));
      case 500:
        return Promise.reject(createApiError("Internal server error", error, ERROR_CODES.SERVER_ERROR, status));
      default:
        return Promise.reject(createApiError("Unknown error occurred", error, ERROR_CODES.UNKNOWN_ERROR, status));
    }
  },
);

// Refresh token if expired
// This function checks if the user's ID token has expired
function isAccessTokenExpired(session: CognitoUserSession): boolean {
  const accessTokenExpire = session.getAccessToken().getExpiration();
  const currentTimeSeconds = Math.round(new Date().getTime() / 1000);
  return accessTokenExpire < currentTimeSeconds;
}

// This function refreshes the user's session using their refresh token
async function refreshSession(refreshToken: CognitoRefreshToken): Promise<CognitoUserSession> {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    currentAuthenticatedUser.refreshSession(refreshToken, (err: any, data: any) => {
      if (err) {
        Auth.signOut();
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export default api;
