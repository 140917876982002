import * as SelectPrimitive from "@radix-ui/react-select";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { EditSavedRoleNotesDialog } from "participant/components/EditSavedRoleNotesDialog";
import { SaveRoleDialog } from "participant/components/SaveRoleDialog";
import { SavedRolesRowActions } from "participant/components/SavedRolesRowActions";
import { useUpdateSavedRole } from "participant/hooks/useUpdateSavedRole";
import { getSavedSearchParams } from "participant/utils/getSavedSearchParams.helpers";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { PARTICIPANT_QUERIES, RANKS, RankType, SavedRolesResponse } from "shared/api/participants";
import { USER_OPTIONS } from "shared/api/user/user";
import {
  Comp,
  NotesCellButton,
  NotesCellContentLayout,
  SavedRoleLogoCellContent,
  SavedRoleRoleCell,
} from "shared/components/SavedRoleCellElements";
import { OrderDirection, SortableColumnHeader } from "shared/components/SortableColumnHeader";
import { Button, ButtonIcon } from "shared/components/ds/Button";
import { EmptyState, EmptyStateVisual } from "shared/components/ds/EmptyState";
import { Pagination, PaginationStats } from "shared/components/ds/Pagination/Pagination";
import { Rank } from "shared/components/ds/Rank";
import { Select, SelectContent, SelectGroup, SelectItem } from "shared/components/ds/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "shared/components/ds/Table";
import { toast } from "shared/components/ds/Toast/Toast";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { SAVED_ROLES_SEARCH_PARAM_KEY } from "shared/constants/savedRoles.constants";
import { focusRingStyles } from "shared/styles/focus";
import { getPageTotal, getRowEnd, getRowStart, getSkip } from "shared/util/pagination.helpers";
import { twMerge } from "tailwind-merge";

export const MAX_RESULTS_PER_PAGE = 50;
export function SavedRolesDataTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { term, orderBy, orderDir, page } = getSavedSearchParams(searchParams);
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());

  const params = React.useMemo(() => {
    return {
      params: {
        limit: MAX_RESULTS_PER_PAGE,
        skip: getSkip(page, MAX_RESULTS_PER_PAGE),
        term,
        orderBy: orderBy ?? undefined,
        orderDir: orderDir ?? undefined,
      },
    };
  }, [term, orderBy, orderDir, page]);
  const deferredParams = React.useDeferredValue(params);
  const isDeferred = params !== deferredParams;

  const { data: saved } = useSuspenseQuery(PARTICIPANT_QUERIES.savedRolesList(user.id.toFixed(), deferredParams));
  const { items: roles, total } = saved ?? {};
  const pageTotal = getPageTotal(total, MAX_RESULTS_PER_PAGE);
  const rowNumStart = getRowStart(page, MAX_RESULTS_PER_PAGE, total);
  const rowNumEnd = getRowEnd(page, MAX_RESULTS_PER_PAGE, total);

  const table = useReactTable({
    columns,
    data: roles,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row, _i) => `${row.id}`,
    manualSorting: true,
    manualPagination: true,
  });

  return (
    <div className="flex flex-col gap-6">
      <div className={twMerge("transition-opacity", isDeferred ? "cursor-wait opacity-50" : "")}>
        <Table
          className={twMerge(
            "grid grid-cols-[1fr_auto_60px] items-center transition-opacity lg:grid-cols-[56px_1fr_15%_25%_10%_60px]",
            isDeferred ? "pointer-events-none" : "",
          )}
        >
          <TableHeader className="z-0 contents">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="contents">
                {headerGroup.headers.map((header) => (
                  <React.Fragment key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className="contents">
            {table.getRowModel().rows?.length !== 0 ? (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id} className="contents">
                      {row.getVisibleCells().map((cell) => (
                        <React.Fragment key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  );
                })}
                <TableRow className="contents">
                  <td className="col-span-full py-2">
                    <SaveRoleDialog>
                      <button
                        className={focusRingStyles({
                          className:
                            "text-ds-text-secondary hover:bg-ds-bg-weaker hover:text-ds-text-primary active:bg-ds-bg-soft flex items-center gap-2 rounded-md px-3 py-2 transition-colors lg:pr-6",
                        })}
                      >
                        <span className="inline-flex w-auto justify-center lg:w-[56px]">
                          <Icon className="h-5 w-5">
                            <IconUse id="add-fill" />
                          </Icon>
                        </span>
                        <span className="text-sm font-medium leading-none">Save new role</span>
                      </button>
                    </SaveRoleDialog>
                  </td>
                </TableRow>
              </>
            ) : (
              <TableRow className="contents">
                <TableCell className="col-span-full">
                  <EmptyState
                    visual={
                      <EmptyStateVisual
                        visual={
                          <Icon className="h-full w-full">
                            <IconUse id="database-2-line" />
                          </Icon>
                        }
                      />
                    }
                    title="No Saved Roles found"
                    description="Try adjusting your search or save a new role."
                  >
                    <SaveRoleDialog>
                      <Button
                        size="sm"
                        variant="secondary"
                        prefix={
                          <ButtonIcon>
                            <IconUse id="add-fill" />
                          </ButtonIcon>
                        }
                      >
                        Save Role
                      </Button>
                    </SaveRoleDialog>
                  </EmptyState>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="stack-x-6 flex-wrap justify-between">
        <PaginationStats rowNumStart={rowNumStart} rowNumEnd={rowNumEnd} total={total}>
          Saved Roles
        </PaginationStats>
        <Pagination
          currentPage={page}
          totalPages={pageTotal}
          siblingCount={1}
          onPageChange={(page) => {
            setSearchParams((searchParams) => {
              if (!page) {
                searchParams.delete(SAVED_ROLES_SEARCH_PARAM_KEY.PAGE);
                return searchParams;
              }

              searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.PAGE, page);

              return searchParams;
            });
          }}
        />
      </div>
    </div>
  );
}
const columnHelper = createColumnHelper<SavedRolesResponse["items"][number]>();
const columns = [
  columnHelper.accessor((row) => row, {
    id: "logo",
    header: () => (
      <TableHead className="hidden items-center lg:flex">
        <span className="sr-only">Company Logo</span>
      </TableHead>
    ),
    cell: ({ getValue }) => {
      const row = getValue();

      return (
        <TableCell className="hidden self-start lg:table-cell">
          <SavedRoleLogoCellContent row={row} />
        </TableCell>
      );
    },
  }),
  columnHelper.accessor((row) => row, {
    id: "role",
    header: () => {
      return <TableHead className="flex items-center rounded-l-md lg:rounded-none">Role</TableHead>;
    },
    cell: ({ getValue }) => {
      const row = getValue();

      return <SavedRoleRoleCell row={row} />;
    },
  }),
  columnHelper.accessor((row) => ({ compensation: row.compensation, payType: row.payType }), {
    id: "compensation",
    header: () => {
      return (
        <SavedRolesDataTableHeader orderById="compensation" className="hidden items-center lg:flex">
          Compensation
        </SavedRolesDataTableHeader>
      );
    },
    cell: ({ getValue }) => {
      const { compensation, payType } = getValue();

      return (
        <TableCell className="hidden lg:table-cell">
          <Comp comp={compensation} payType={payType} />
        </TableCell>
      );
    },
  }),
  columnHelper.accessor(
    (row) => ({
      notes: row.notes,
      id: row.id,
      type: row.type,
    }),
    {
      id: "notes",
      header: () => {
        return (
          <TableHead className="hidden items-center lg:flex">
            <span className="inline-block pl-2">Notes</span>
          </TableHead>
        );
      },
      cell: ({ getValue }) => {
        const { id, notes } = getValue();

        if (notes == null || notes.length <= 0) {
          return (
            <TableCell className="hidden lg:table-cell">
              <EditSavedRoleNotesDialog notes={notes ?? ""} savedRoleId={id}>
                <NotesCellButton state="empty">
                  <NotesCellContentLayout>Add Note</NotesCellContentLayout>
                </NotesCellButton>
              </EditSavedRoleNotesDialog>
            </TableCell>
          );
        }

        return (
          <TableCell className="hidden lg:table-cell">
            <EditSavedRoleNotesDialog notes={notes ?? ""} savedRoleId={id}>
              <NotesCellButton state="filled">
                <NotesCellContentLayout>{notes}</NotesCellContentLayout>
              </NotesCellButton>
            </EditSavedRoleNotesDialog>
          </TableCell>
        );
      },
    },
  ),
  columnHelper.accessor(
    (row) => ({
      id: row.id,
      rank: row.rank,
      type: row.type,
    }),
    {
      id: "rank",
      header: () => {
        return (
          <SavedRolesDataTableHeader orderById="rank" className="items-center lg:flex">
            Rank
          </SavedRolesDataTableHeader>
        );
      },
      cell: ({ getValue }) => {
        const { id: savedRoleId, rank } = getValue();

        return <RankCell key={rank} rank={rank} savedRoleId={savedRoleId} />;
      },
    },
  ),
  columnHelper.accessor((row) => row, {
    id: "actions",
    header: () => {
      return (
        <TableHead>
          <span className="sr-only">Actions</span>
        </TableHead>
      );
    },
    cell: ({ getValue }) => {
      const row = getValue();

      return (
        <TableCell className="justify-self-center lg:justify-self-end">
          <SavedRolesRowActions row={row} />
        </TableCell>
      );
    },
  }),
];

function RankCell({ rank, savedRoleId }: { rank?: RankType; savedRoleId: number }) {
  const mutation = useUpdateSavedRole(savedRoleId);

  return (
    <TableCell>
      <Select
        defaultValue={rank}
        onValueChange={(value: RankType | "None") => {
          toast.promise(
            mutation.mutateAsync({
              rank: value === "None" ? null : value,
            }),
            {
              loading: "Updating rank...",
              success: "Successfully updated rank",
              error: "Failed to update rank",
            },
          );
        }}
        disabled={mutation.isPending}
      >
        <SelectPrimitive.Trigger asChild>
          <Button svgOnly variant="ghost">
            <Rank aria-hidden level={rank} />
            <span className="sr-only">Change rank from "{rank ?? "None"}"</span>
          </Button>
        </SelectPrimitive.Trigger>

        <SelectContent className="w-[180px] p-1" side="left" align="start">
          <SelectGroup>
            {RANK_ITEMS.map((rank) => {
              const level = rank === "None" ? undefined : rank;

              return (
                <SelectItem key={rank} value={rank}>
                  <span className="flex gap-2">
                    <Rank level={level} />
                    <span>{rank}</span>
                  </span>
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
    </TableCell>
  );
}

function SavedRolesDataTableHeader({
  orderById,
  className,
  children,
}: {
  orderById: string;
  className?: string;
  children?: React.ReactNode;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderDir: currentOrderDir, orderBy: currentOrderBy } = getSavedSearchParams(searchParams);
  const isCurrentOrderBy = currentOrderBy === orderById;

  function handleDirectionChange(orderDir: OrderDirection | null) {
    setSearchParams((searchParams) => {
      if (orderDir === null) {
        searchParams.delete(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_BY);
        searchParams.delete(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_DIR);
        return searchParams;
      }

      searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_BY, orderById);
      searchParams.set(SAVED_ROLES_SEARCH_PARAM_KEY.ORDER_DIR, orderDir);

      return searchParams;
    });
  }

  if (!isCurrentOrderBy) {
    return (
      <SortableColumnHeader onDirectionChange={handleDirectionChange} orderDir={null} className={className}>
        {children}
      </SortableColumnHeader>
    );
  }

  return (
    <SortableColumnHeader onDirectionChange={handleDirectionChange} orderDir={currentOrderDir} className={className}>
      {children}
    </SortableColumnHeader>
  );
}

const RANK_ITEMS = ["None", ...RANKS] as const;
