import React from "react";
import App from "./App";
import "./index.css";

import { createRoot } from "react-dom/client";
import { PostHogProvider } from "shared/components/PostHogProvider";
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <PostHogProvider>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
