import { rem } from "polished";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";
import { tv } from "tailwind-variants";

export const headingOne = tv({
  base: "text-4xl md:text-5xl mb-[0.67em]",
  variants: {
    size: {
      smaller: "md:text-4xl",
    },
    fixed: {
      true: "m-0 h-24 flex flex-col justify-end",
    },
  },
});

export const H2 = styled.h2`
  font-size: ${rem(36)};

  ${screen.below(
    bp.mobile,
    `
    font-size: ${rem(28)};
  `,
  )}
`;

export const H3 = styled.h3`
  font-size: ${rem(27)};

  ${screen.below(
    bp.mobile,
    `
    font-size: ${rem(21)};
  `,
  )}
`;

export const H4 = styled.h4`
  font-size: ${rem(20)};

  ${screen.below(
    bp.mobile,
    `
    font-size: ${rem(18)};
  `,
  )}
`;

export const H5 = styled.h5`
  font-size: ${rem(16)};
`;

export const H6 = styled.h6`
  font-size: ${rem(15)};
`;
