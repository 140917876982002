import * as React from "react";
import { getTransformedRange } from "shared/components/ds/Pagination/getTransformedRange";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { text } from "shared/styles/text";
import { twMerge } from "tailwind-merge";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  siblingCount: number;
  onPageChange: (page?: string) => void;
}
export function Pagination({ currentPage, totalPages, siblingCount, onPageChange }: PaginationProps) {
  const pages = getTransformedRange({
    page: currentPage,
    totalPages,
    siblingCount,
  });

  return totalPages > 1 ? (
    <PaginationContainer>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            aria-disabled={currentPage === 1}
            onClick={() => {
              const newPage = Math.max(currentPage - 1, 1).toString();

              onPageChange(newPage);
            }}
          />
        </PaginationItem>
        {pages.map((page, index) => {
          if (page.type === "page") {
            return (
              <PaginationItem key={page.value}>
                <PaginationButton
                  isActive={currentPage === page.value}
                  onClick={() => {
                    const newPage = page.value?.toString();

                    onPageChange(newPage);
                  }}
                >
                  {page.value}
                </PaginationButton>
              </PaginationItem>
            );
          }
          return (
            <PaginationItem key={`ellipses-${index}`}>
              <PaginationEllipsis />
            </PaginationItem>
          );
        })}
        <PaginationItem>
          <PaginationNext
            aria-disabled={currentPage === totalPages}
            onClick={() => {
              const newPage = Math.min(currentPage + 1, totalPages).toString();

              onPageChange(newPage);
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </PaginationContainer>
  ) : null;
}

export function PaginationContainer({ className, ...rest }: React.ComponentProps<"nav">) {
  return <nav role="navigation" aria-label="pagination" {...rest} />;
}

export const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentPropsWithoutRef<"ul">>(
  ({ className, ...rest }, ref) => (
    <ul
      ref={ref}
      className={twMerge(
        "border-ds-stroke-tertiary bg-ds-bg-foundation m-0 inline-flex list-none items-center rounded-full border p-0 shadow-sm",
        className,
      )}
      {...rest}
    />
  ),
);
PaginationContent.displayName = "PaginationContent";

export const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentPropsWithoutRef<"li">>((props, ref) => (
  <li ref={ref} {...props} />
));
PaginationItem.displayName = "PaginationItem";

interface PaginationButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  isActive?: boolean;
}
export function PaginationButton({ className, isActive, ...rest }: PaginationButtonProps) {
  return (
    <button
      aria-current={isActive ? "page" : undefined}
      className={twMerge(
        'text-ds-text-secondary after:bg-ds-primary-base hover:bg-ds-bg-weaker aria-disabled:text-ds-text-secondary/60 aria-[current="page"]:text-ds-text-primary relative flex h-8 w-8 items-center justify-center text-sm no-underline transition-colors after:invisible after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:translate-y-[1px] after:rounded-full aria-disabled:cursor-not-allowed aria-[current="page"]:font-medium aria-[current="page"]:after:visible',
        className,
      )}
      {...rest}
    />
  );
}

export function PaginationPrevious({ className, ...rest }: React.ComponentProps<typeof PaginationButton>) {
  return (
    <PaginationButton
      aria-label="Go to previous page"
      className={twMerge(
        'before:bg-ds-stroke-tertiary before:inset-y-center relative h-8 w-9 rounded-l-full before:absolute before:right-0 before:h-4 before:w-[1px] before:content-[""] after:!hidden',
        className,
      )}
      {...rest}
    >
      <Icon className="h-4 w-4">
        <IconUse id="arrow-left-s-line" />
      </Icon>
      <span className="sr-only">Previous</span>
    </PaginationButton>
  );
}

export function PaginationNext({ className, ...rest }: React.ComponentProps<typeof PaginationButton>) {
  return (
    <PaginationButton
      aria-label="Go to next page"
      className={twMerge(
        'before:bg-ds-stroke-tertiary before:inset-y-center relative h-8 w-9 rounded-r-full before:absolute before:left-0 before:h-4 before:w-[1px] before:content-[""] after:!hidden',
        className,
      )}
      {...rest}
    >
      <Icon className="h-4 w-4">
        <IconUse id="arrow-right-s-line" />
      </Icon>
      <span className="sr-only">Next</span>
    </PaginationButton>
  );
}

export function PaginationEllipsis({ className, ...rest }: React.ComponentProps<"span">) {
  return (
    <span
      aria-hidden
      className={twMerge("text-ds-text-secondary flex h-8 w-8 items-center justify-center", className)}
      {...rest}
    >
      <Icon className="h-3 w-4">
        <IconUse id="ri-more-fill" />
      </Icon>
      <span className="sr-only">More pages</span>
    </span>
  );
}

interface PaginationStatsProps {
  total: number;
  rowNumStart: number;
  rowNumEnd: number;
  children?: React.ReactNode;
}
export function PaginationStats({ total, rowNumStart, rowNumEnd, children }: PaginationStatsProps) {
  return (
    <span
      className={text({
        variant: "14",
        color: "secondary",
      })}
    >
      Showing{" "}
      <span className="text-ds-text-primary font-medium">
        {rowNumStart}-{rowNumEnd}
      </span>{" "}
      of <span className="text-ds-text-primary font-medium">{total}</span> {children}
    </span>
  );
}
