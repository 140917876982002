import styled from "styled-components";

const GridRoot = styled.div<{
  formGrid?: boolean;
  cols?: number;
}>`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: repeat(${(props) => props.cols || 2}, minmax(0, 1fr));
  min-height: 0; /* NEW */
  min-width: 0; /* NEW; needed for Firefox */

  ${(props) =>
    props.formGrid &&
    `
    grid-row-gap: var(--form-vertical-space);
  `}
`;

const GridItem = styled.div<{
  span?: number;
}>`
  grid-column: span ${(props) => props.span || 1};
`;

type GridType = typeof GridRoot & {
  Item: typeof GridItem;
};

const Grid: GridType = Object.assign(GridRoot, {
  Item: GridItem,
});

export default Grid;
