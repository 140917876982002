import { add, format } from "date-fns";

export const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const phonePattern = /((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/;

export const phoneMask = ["(", /[0-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export const urlPattern = /(https?:\/\/)?([\w-])+\.{1}([a-zA-Z]{2,63})([\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/;

export const linkedinPattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/.*/;

export const brainstormStatusOptions = [
  { label: "Need Info From Participant", value: "Need Info From Participant" },
  { label: "Call Scheduled", value: "Call Scheduled" },
  { label: "Undecided", value: "Undecided" },
  { label: "Keep Warm", value: "Keep Warm" },
  { label: "Future Fit", value: "Future Fit" },
  { label: "Not a Fit - Participant", value: "Not a Fit - Participant" },
  { label: "Not a Fit - Partner", value: "Not a Fit - Partner" },
  { label: "Role Closed", value: "Role Closed" },
  { label: "Role on Hold", value: "Role on Hold" },
  { label: "Confirm Partner Interest", value: "Confirm Partner Interest" },
  {
    label: "Confirm BreakLiner Interest",
    value: "Confirm BreakLiner Interest",
  },
  { label: "Company Pinged", value: "Company Pinged" },
  { label: "BreakLiner Pinged", value: "BreakLiner Pinged" },
  { label: "Second Look", value: "Second Look" },
  { label: "Partner Interest Confirmed", value: "Partner Interest Confirmed" },
  {
    label: "BreakLiner Interest Confirmed",
    value: "BreakLiner Interest Confirmed",
  },
  { label: "Interview Initiated", value: "Interview Initiated" },
];

export const experienceOptions = [
  { label: "0-7 years", value: "0 - 7 years" },
  { label: "8-15 years", value: "8 - 15 years" },
  { label: "16+ years", value: "16+ years" },
];

export const experienceRangeOptions = [
  { label: "0-7 years", value: "0,7" },
  { label: "8-15 years", value: "8,15" },
  { label: "16+ years", value: "16" },
];

export const forecastOptions = [
  { label: "High", value: "High" },
  { label: "Low", value: "Low" },
  { label: "Unknown", value: "Unknown" },
];

export const rankOptions = [
  { label: "Rank 1", value: 3 },
  { label: "Rank 2", value: 2 },
  { label: "Rank 3", value: 1 },
  { label: "Remove Rank", value: -1 },
];

export const interviewStatusOptions = [
  { value: "Active", label: "Active" },
  { value: "Canceled", label: "Canceled" },
  { value: "Stalled", label: "Stalled" },
  { value: "Withdrawn", label: "Withdrawn" },
  { value: "Dinged", label: "Dinged" },
];

export const eventTypeOptions = [
  { value: "class", label: "Core Curriculum" },
  { value: "speaker", label: "Speaker Series" },
  { value: "partner", label: "Partner Spotlight" },
  { value: "workshop", label: "Workshop" },
];

// Number of employee select options
export const employeeCountOptions = [
  { label: "1-50", value: "1-50" },
  { label: "51-200", value: "51-200" },
  { label: "201-500", value: "201-500" },
  { label: "501-1,000", value: "501-1000" },
  { label: "1,001-5,000", value: "1001-5000" },
  { label: "5,001-10,000", value: "5001-10000" },
  { label: "10,000+", value: "10000" },
];

export const tierOptions = [
  { label: "Tier 1", value: "Tier 1" },
  { label: "Tier 2", value: "Tier 2" },
  { label: "Tier 3", value: "Tier 3" },
];

export const totalCompensationOptions = [
  { label: "Totally Flexible", value: "Totally Flexible" },
  { label: "$30-$50k", value: "$30-$50k" },
  { label: "$51-$70k", value: "$51-$70k" },
  { label: "$71-$90k", value: "$71-$90k" },
  { label: "$91-$110k", value: "$91-$110k" },
  { label: "$111-$130k", value: "$111-$130k" },
  { label: "$131-$150k", value: "$131-$150k" },
  { label: "$151-$170k", value: "$151-$170k" },
  { label: "$171-$190k", value: "$171-$190k" },
  { label: "$191-$210k", value: "$191-$210k" },
  { label: "$211-$230k", value: "$211-$230k" },
  { label: "$231-$250k", value: "$231-$250k" },
  { label: "$250k+", value: "$250k+" },
];

export const participantWorkAuthOptions = [
  {
    label: "No, I am not legally authorized for employment in the United States",
    value: 0,
  },
  { label: "Yes, but I require sponsorship.", value: 1 },
  {
    label: "Yes, I am legally authorized for employment in the United States",
    value: 2,
  },
];

export const partnerVisaSponsorshipOptions = [
  { label: "No (Can't Sponsor)", value: 0 },
  { label: "Maybe (Can Potentially Sponsor)", value: 1 },
  { label: "Yes (Can Sponsor)", value: 2 },
];

export const getDateAvailableOptions = () => {
  const now = new Date();
  const dateFormat = "yyyy-MM-dd";
  const nowString = format(now, dateFormat);

  const getFutureDate = (months) => {
    return format(add(now, { months: months }), dateFormat);
  };

  return [
    {
      value: nowString,
      label: "Available Now",
    },
    {
      value: `${nowString},${getFutureDate(3)}`,
      label: "0 - 3 months",
    },
    {
      value: `${getFutureDate(3)},${getFutureDate(6)}`,
      label: "3 - 6 months",
    },
    {
      value: `${getFutureDate(6)},${getFutureDate(12)}`,
      label: "6 - 12 months",
    },
    {
      value: `${getFutureDate(12)}`,
      label: "12+ months",
    },
  ];
};

export const formSWROptions = {
  revalidateOnMount: true,
  revalidateOnReconnect: false,
  revalidateOnFocus: false,
};

/**
 * Formats React Hook Form values. If value is an empty string it is omitted. If the value is an object (ex: from Combo
 * component) it will be transformed like so: fieldName: { label: 'label', value: 'value' } --> `fieldName: value`
 *
 * @param {object} values - React Hook Form values object.
 * @param {object} allowEmpty - If true, null values will be allowed through.
 */
export const formatFormValues = (values, allowEmpty = false) => {
  if (!values) return;

  const data = Object.keys(values).reduce((prev, key) => {
    let keyValue = values[key];

    if (allowEmpty && (keyValue === null || keyValue === "" || !keyValue)) {
      return {
        ...prev,
        [key]: keyValue,
      };
    }
    // Prevent Flex Modules from submitting
    if (/.{8}-(.{4}-){3}.{12}\|/.test(key)) return prev;
    // IDK how but sometimes there's no key. If that's the case let's
    // remove it. I think it's from adding/removing flex modules?
    // - Brett
    if (!keyValue) return prev;

    // Don't want to send the entire AWS image object. Only
    // the ID.
    if (keyValue?.signedUrl) {
      keyValue = values[key].id;
    }

    // Prevent sending empty objects from Combo inputs
    if (keyValue.constructor === Object && Object.keys(keyValue).length === 0) {
      return {
        ...prev,
        [key]: null,
      };
    }

    // Handle MultiSelects
    if (key !== "formBuilder" && Array.isArray(keyValue)) {
      keyValue = keyValue.map((item) => {
        if (key === "personas") {
          return {
            id: item.value || item.id,
            relevantExperience: item.relevantExperience || 0,
          };
        }

        return item.value ? item.value : item.id;
      });
    }

    return {
      ...prev,
      ...(keyValue !== "" ? { [key]: keyValue.value || keyValue } : {}),
    };
  }, {});

  return data;
};

/**
 * Returns a function for converting an object to the standard option format. {value, label}
 *
 * @param {string} valueProp The prop of the input object to use as the value.
 * @param {string} lableProp The prop of the input object to use as the label.
 */
export const getOptionConverter = (valueProp, labelProp) => {
  return (option) => {
    if (!option) return {};
    return { value: option[valueProp], label: option[labelProp] };
  };
};

export const segmentConverter = (option) => {
  if (!option) return {};
  return { value: option.id, skills: option.skills, label: option.name };
};

/**
 * Finds the option object from the given option array based on the given value
 *
 * @param {any} val The value of the option you want.
 * @param {Array} opts The array of options to search.
 */
export const getOptionFromValue = (val, opts) => opts.find((o) => o.value === val) || {};

/**
 * Finds the option object from the rank options and then returns the correct label.
 *
 * @param {any} val The value of the option you want.
 */
export const getRankFromValue = (val) => {
  let rank = getOptionFromValue(val, rankOptions);

  if (rank?.label) {
    return Number.parseInt(rank.label.replace("Rank ", ""), 10);
  }

  return "";
};

/**
 * Converts a participant from the DB to the standard option format. {value, label}
 *
 * @param {object} p The participant data.
 */
export const participantConverter = (p) => {
  if (!p || !p.id) return {};
  return {
    ...p,
    value: p.id,
    label: `${p.firstName} ${p.lastName}`,
  };
};

/**
 * Formats data from API to be used with MultiSelect component
 *
 * @param {array} values
 * @returns {array} FormattedValues
 */
export const responseToMultiSelect = (values) => {
  if (!values) return [];

  return values.map((value) => ({
    value: value?.id,
    label: value?.name,
  }));
};

/**
 * Formats User/Job Persona data from API to be used with MultiSelect component
 *
 * @param {array} values
 * @returns {array} FormattedValues
 */
export const personaToMultiSelect = (values) => {
  if (!values) return [];

  return values.map((value) => ({
    value: value?.persona?.id,
    label: value?.persona?.name,
    relevantExperience: value?.relevantExperience,
  }));
};

/**
 * Converts a job from the DB to the standard option format. {value, label} Adds locations to the label
 *
 * @param {object} job
 * @returns {object} The converted onbject
 */
export const jobConverter = (job) => {
  if (!job || !job.id) return {};
  const locations = job.locations?.map((l) => l.name);
  return {
    value: job?.id,
    label: `${job?.title} ${locations?.length > 0 ? `– ${locations.join(" | ")}` : ""}`,
    prettyLabel: (
      <div style={{ display: "grid", gridGap: 7, gridTemplateColumns: "16px 1fr" }}>
        {job?.aggregates?.highPriority ? (
          <img alt="featured" style={{ width: 16, height: 21, marginTop: 1 }} src="/images/flame.svg" />
        ) : (
          <div />
        )}
        {job?.title} {locations?.length > 0 ? `– ${locations.join(" | ")}` : null}
      </div>
    ),
  };
};

/**
 * Converts a masked number string into a number.
 *
 * @param {number | string} The Value to parse.
 * @returns The converted masked number.
 */
export const getNumber = (s) => {
  if (typeof s === "number") return s;
  if (!s) return null;
  s = s.replace(/[$,]+/g, "");
  return parseFloat(s);
};
