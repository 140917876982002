import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import apiService from "shared/api/api-service";
import { JOBS_KEYS } from "shared/api/jobs";
import Button from "shared/components/Button";
import ButtonGroup from "shared/components/ButtonGroup";
import Grid from "shared/components/Grid";
import { H3, H4, H5 } from "shared/components/H";
import Modal from "shared/components/Modal";
import { toast } from "shared/components/ds/Toast/Toast";
import TextEditor from "shared/components/forms/TextEditor";
import { TOAST_MESSAGE } from "shared/constants/toast.constants";
import { formatFormValues } from "shared/util/forms";

const defaultValues = {
  body: "<p>Test</p>",
};

// Some values need to be adjusted for populating form fields
const mapToFormValues = (data) => {
  if (!data || !data.recruiter || !data.participant) return { body: "" };

  const recName = `${data.recruiter.firstName} ${data.recruiter.lastName}`;
  const recLink = data.recruiter.profile?.linkedin;
  const recLinked = recLink ? `<a href="${recLink}">${recName}</a>` : recName;
  const pName = `${data.participant.firstName} ${data.participant.lastName}`;
  const pLink = data.participant.profile?.linkedin;
  const pLinked = pLink ? `<a href="${pLink}">${pName}</a>` : pName;

  const msgText = `
    <p>${data.recruiter.firstName} & ${data.participant.firstName},</p>
    <p>It's a pleasure to connect you!</p>
    <p>${data.recruiter.firstName} - ${pLinked} is ${data.participant.profile?.oneLiner || ""}</p>
    <p>${data.participant.firstName} - ${recLinked} is ${data.recruiter.profile?.oneLiner || ""}</p>
    <p>Please feel free to reach out directly from here!</p>
    <p>Cheers,<br />
    ${data.currentUser?.firstName}</p>
  `;

  return { body: msgText };
};

const InterviewIntroModal = ({ modalData, modalOpen, requestClose }) => {
  const queryClient = useQueryClient();
  const { handleSubmit, control, reset } = useForm({
    mode: "onTouched",
    defaultValues,
  });

  useEffect(() => {
    reset(mapToFormValues(modalData));
  }, [modalData, reset]);

  const onSubmit = (values) => {
    let introValues = formatFormValues(values);
    introValues = {
      ...introValues,
      participant: modalData.participant.id,
      recruiter: modalData.recruiter.id,
      interviewId: modalData.id,
    };

    apiService
      .save("interviews/introduction", introValues)
      .then(() => {
        toast.success("Introduction Sent Successfully");
        queryClient.invalidateQueries({
          queryKey: JOBS_KEYS.process(modalData?.job?.id),
        });
        requestClose(true);
      })
      .catch((error) => {
        toast.error(TOAST_MESSAGE.error("Introduction"));
      });
  };

  // Empty the form on modal close
  useEffect(() => {
    if (!modalOpen) {
      reset(defaultValues);
    }
  }, [modalOpen, reset]);

  return (
    <Modal isOpen={!!modalOpen} onRequestClose={requestClose} large={modalOpen !== "confirmDelete"}>
      {modalOpen === "confirmDelete" ? (
        <>
          <H4>Delete {modalData.name}</H4>
          <p>Are you sure you want to delete this interview?</p>
          <ButtonGroup>
            <Button
              $theme="error"
              type="submit"
              onClick={() => {
                apiService
                  .delete("interviews", { id: modalData.id })
                  .then(() => {
                    toast.success(`Interview Deleted`);
                    requestClose(true);
                  })
                  .catch((err) => {
                    toast.error(`There was an error deleting this interview.`);
                  });
              }}
            >
              Delete
            </Button>
            <Button
              $theme="secondary-error"
              onClick={() => {
                requestClose();
              }}
            >
              Cancel
            </Button>
          </ButtonGroup>
        </>
      ) : (
        <>
          <H3 style={{ marginBottom: "20px" }}>
            Introduction Email: {modalData.job?.title}
            {modalData.partner ? `, ${modalData.partner.name}` : ""}
          </H3>
          {!modalData.recruiter || !modalData.participant ? (
            <div>Missing Participant or Recruiter</div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <H4 style={{ marginBottom: "20px" }}>
                Subject: Connecting {modalData.recruiter.firstName} {modalData.recruiter.lastName} &amp;&nbsp;
                {modalData.participant.firstName} {modalData.participant.lastName}
              </H4>
              <Grid formGrid>
                {modalData.recruiter ? (
                  <div>
                    <H5>Recruiter</H5>
                    <span>
                      {modalData.recruiter.firstName} {modalData.recruiter.lastName}
                    </span>
                  </div>
                ) : null}

                <div>
                  <H5>Participant</H5>
                  <span>
                    {modalData.participant.firstName} {modalData.participant.lastName}
                  </span>
                </div>

                <Controller
                  as={TextEditor}
                  control={control}
                  name="body"
                  label="Message"
                  placeholder="Type your message here."
                  hideToolbar
                />

                <ButtonGroup>
                  <Button type="submit">Send</Button>
                  <Button
                    $theme="secondary"
                    onClick={() => {
                      requestClose();
                    }}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              </Grid>
            </form>
          )}
        </>
      )}
    </Modal>
  );
};

export default InterviewIntroModal;
