import { FilterGroupState, filterSchema } from "shared/components/FiltersV2/types/filters";
import { parseSearchParams } from "shared/util/searchParams.helpers";

export function parseFiltersFromUrl(searchParams: URLSearchParams, key: string) {
  const parsed = parseSearchParams(searchParams, key);

  // validate the filters
  const validatedFilters = Object.entries(parsed).reduce((acc, [groupId, filters]) => {
    try {
      const parsedFilters = filterSchema.array().catch([]).parse(filters);

      // If it's an empty array, we know that it's invalid and probably isn't a filter, therefore we can ignore it and return the accumulator
      if (parsedFilters.length === 0) {
        return acc;
      }

      acc[groupId] = parsedFilters?.map((f) => ({
        id: f.id,
        name: f.name,
      }));

      return acc;
    } catch (err) {
      return acc;
    }
  }, {} as FilterGroupState);

  return validatedFilters;
}
