import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";
import { focusRingStyles } from "shared/styles/focus";
import { tv, VariantProps } from "tailwind-variants";

export const Tabs = TabsPrimitive.Root;

export const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...rest }, ref) => {
  const { list } = tabsStyles();

  return <TabsPrimitive.List ref={ref} className={list({ className })} {...rest} />;
});
TabsList.displayName = TabsPrimitive.List.displayName;

export const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & VariantProps<typeof tabsStyles>
>(({ className, size, ...rest }, ref) => {
  const { trigger } = tabsStyles({ size });

  return <TabsPrimitive.Trigger ref={ref} className={trigger({ className })} {...rest} />;
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

export const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...rest }, ref) => {
  return (
    <TabsPrimitive.Content
      ref={ref}
      className={focusRingStyles({
        className,
      })}
      {...rest}
    />
  );
});
TabsContent.displayName = TabsPrimitive.Content.displayName;

export const tabsStyles = tv({
  slots: {
    list: "inline-flex overflow-x-auto w-full items-center [box-shadow:0_-1px_0_rgb(var(--stroke-tertiary))_inset] scrollbar-none",
    trigger: focusRingStyles({
      className:
        "whitespace-nowrap flex items-center justify-center border-b-2 border-transparent  text-center font-normal text-ds-text-secondary  hover:border-ds-stroke-secondary hover:text-ds-text-primary aria-selected:border-ds-primary-base aria-selected:font-medium aria-selected:text-ds-text-primary aria-selected:hover:border-ds-primary-dark [&.active]:border-ds-primary-base [&.active]:font-medium [&.active]:text-ds-text-primary [&.active]:hover:border-ds-primary-dark no-underline relative transition-all after:invisible after:absolute after:top-0 after:z-[-1] after:flex after:h-[2em] after:w-[calc(100%-12px)] after:rounded-md after:bg-ds-bg-weaker after:inset-center focus-visible:after:visible hover:after:visible",
    }),
  },
  variants: {
    size: {
      small: {
        trigger: "text-sm h-9 px-3",
      },
      default: {
        trigger: "text-base h-11 px-4",
      },
    },
  },
  defaultVariants: {
    size: "default",
  },
});
