import { tv } from "tailwind-variants";

export const focusRingStyles = tv({
  base: "focus-visible:outline-none focus-visible:ring-2",
  variants: {
    variant: {
      default:
        "focus-visible:ring-ds-stroke-tertiary focus-visible:ring-offset-2 focus-visible:ring-offset-ds-bg-foundation",
      input:
        "focus-visible:border-ds-neutral-400 focus-visible:bg-ds-bg-foundation focus-visible:text-ds-text-primary focus-visible:ring-ds-stroke-secondary",
    },
    interactiveChildren: {
      true: "",
    },
  },
  compoundVariants: [
    {
      variant: "default",
      interactiveChildren: true,
      className:
        "focus-within:ring-2 focus-within:ring-ds-stroke-tertiary focus-within:ring-offset-2 focus-within:ring-offset-ds-bg-foundation",
    },
    {
      variant: "input",
      interactiveChildren: true,
      className:
        "focus-within:ring-2 focus-within:border-ds-neutral-400 focus-within:bg-ds-bg-foundation focus-within:text-ds-text-primary focus-within:ring-ds-stroke-secondary",
    },
  ],
  defaultVariants: {
    variant: "default",
    interactiveChildren: false,
  },
});
