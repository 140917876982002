const Symbols = () => (
  <svg style={{ display: "none" }}>
    <symbol
      id="chevron"
      viewBox="0 0 16 9"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.85 1L7.925 7.844 1 1" />
    </symbol>
    <symbol
      id="x-icon"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    >
      <path d="M1 1l12 12" />
      <path d="M13 1L1 13" />
    </symbol>
    <symbol
      id="error-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.7"
    >
      <circle cx="9.861" cy="9.861" r="9.011" />
      <path d="M13.073 6.648l-6.425 6.425" />
      <path d="M6.648 6.648l6.425 6.425" />
    </symbol>
    <symbol
      id="success-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.7"
    >
      <circle cx="9.861" cy="9.861" r="9.011" />
      <path d="M14.023 7L8.77 12.721l-3.072-2.673" />
    </symbol>
    <symbol
      id="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.7"
    >
      <path d="M14.023 7L8.77 12.721l-3.072-2.673" />
    </symbol>
    <symbol id="info-icon" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4 7A6.4 6.4 0 1 1 .6 7a6.4 6.4 0 0 1 12.8 0ZM7.8 3.8a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0ZM6.2 6.2a.8.8 0 1 0 0 1.6v2.4a.8.8 0 0 0 .8.8h.8a.8.8 0 0 0 0-1.6V7a.8.8 0 0 0-.8-.8h-.8Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="task-icon" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16">
      <g clipPath="url(#task-icon-clip)" fill="currentColor">
        <path d="M5 0a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2H5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3a2 2 0 0 1 2-2 3 3 0 0 0 3 3h2a3 3 0 0 0 3-3 2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3Zm3 4a1 1 0 0 0 0 2h.01a1 1 0 0 0 0-2H3Zm3 0a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H6Zm-3 4a1 1 0 0 0 0 2h.01a1 1 0 0 0 0-2H3Zm3 0a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H6Z"
        />
      </g>
      <defs>
        <clipPath id="task-icon-clip">
          <path fill="currentColor" d="M0 0h12v16H0z" />
        </clipPath>
      </defs>
    </symbol>
    <symbol id="microphone" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.429 2.714a2.571 2.571 0 0 1 5.142 0v3.429a2.571 2.571 0 0 1-5.142 0V2.714Zm3.428 9.369A6 6 0 0 0 12 6.143a.857.857 0 1 0-1.714 0 4.286 4.286 0 1 1-8.572 0 .857.857 0 0 0-1.714 0 6 6 0 0 0 5.143 5.94v1.774H2.57a.857.857 0 1 0 0 1.714H9.43a.857.857 0 1 0 0-1.714H6.857v-1.774Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="filter" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
      <path
        d="M3 1a1 1 0 1 0-2 0v7.268a2 2 0 0 0 0 3.464V13a1 1 0 1 0 2 0v-1.268a2 2 0 0 0 0-3.464V1Zm6 0a1 1 0 1 0-2 0v1.268a2 2 0 0 0 0 3.464V13a1 1 0 1 0 2 0V5.732a2 2 0 0 0 0-3.464V1Zm5-1a1 1 0 0 1 1 1v7.268a2 2 0 0 1 0 3.464V13a1 1 0 1 1-2 0v-1.268a2 2 0 0 1 0-3.464V1a1 1 0 0 1 1-1Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="right-arrow-circle" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16.5A8 8 0 1 0 8 .499 8 8 0 0 0 8 16.5Zm3.707-8.707-3-3a1 1 0 0 0-1.414 1.414L8.586 7.5H5a1 1 0 0 0 0 2h3.586l-1.293 1.293a1 1 0 1 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414Z"
        fill="currentColor"
      />
    </symbol>
    <symbol id="closed-captioning" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 4A3.25 3.25 0 0 1 22 7.25v9.505a3.25 3.25 0 0 1-3.25 3.25H5.25A3.25 3.25 0 0 1 2 16.755V7.25a3.25 3.25 0 0 1 3.066-3.245L5.25 4h13.5Zm0 1.5H5.25l-.144.006A1.75 1.75 0 0 0 3.5 7.25v9.505c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75V7.25a1.75 1.75 0 0 0-1.75-1.75ZM5.5 12c0-3.146 2.713-4.775 5.122-3.401A.75.75 0 1 1 9.878 9.9C8.481 9.104 7 9.994 7 12c0 2.005 1.484 2.896 2.88 2.103a.75.75 0 1 1 .74 1.304C8.216 16.775 5.5 15.143 5.5 12Zm7.5 0c0-3.146 2.713-4.775 5.122-3.401a.75.75 0 1 1-.744 1.302C15.981 9.104 14.5 9.994 14.5 12c0 2.005 1.484 2.896 2.88 2.103a.75.75 0 1 1 .74 1.304C15.716 16.775 13 15.143 13 12Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </symbol>
    <symbol id="home" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.707 2.293a1 1 0 0 0-1.414 0l-7 7a1 1 0 0 0 1.414 1.414L4 10.414V17a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6.586l.293.293a1 1 0 0 0 1.414-1.414l-7-7Z"
        fill="currentColor"
      />
    </symbol>
  </svg>
);

export default Symbols;
