import * as LabelPrimitive from "@radix-ui/react-label";
import * as React from "react";
import { twMerge } from "tailwind-merge";

export interface LabelProps extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  required?: boolean;
  optional?: boolean;
}
export const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, required, optional, children, ...rest }, ref) => {
    return (
      <LabelPrimitive.Root
        ref={ref}
        className={twMerge(
          "peer-aria-[invalid=true]:text-ds-state-error inline-flex items-center gap-1 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
          className,
        )}
        {...rest}
      >
        {children}
        {required && (
          <span className="inline-flex text-current" aria-hidden>
            *
          </span>
        )}
        {optional && (
          <span className="text-ds-text-tertiary inline-flex text-xs font-normal" aria-hidden>
            (optional)
          </span>
        )}
      </LabelPrimitive.Root>
    );
  },
);
Label.displayName = LabelPrimitive.Root.displayName;
