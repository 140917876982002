/**
 * Helper function to trim a list & tell how many items were trimmeed
 *
 * @param {array} list
 * @param {number} limit
 * @returns {object} TrimmedList
 */
const makeLimitedList = (list, limit) => {
  if (!list)
    return {
      list,
      remainder: false,
    };

  const remainder = list.length - limit;

  return {
    list: list.slice(0, limit),
    remainder: remainder > 0 && remainder,
  };
};

export default makeLimitedList;
