import * as React from "react";
import { LoadingContainer } from "shared/components/ds/Spinner";
import { VariantProps, tv } from "tailwind-variants";

type TableOverlayProps = VariantProps<typeof tableOverlayContainer> & React.ComponentPropsWithoutRef<"div">;
export const TableOverlay = React.forwardRef<HTMLDivElement, TableOverlayProps>(
  ({ pending, className, ...rest }, ref) => {
    return <div {...rest} className={tableOverlayContainer({ pending, className })} ref={ref} />;
  },
);

const tableOverlayContainer = tv({
  base: "relative",
  variants: {
    pending: {
      true: "opacity-50 cursor-wait",
    },
  },
});

type TableLoaderProps = VariantProps<typeof tableLoaderContainer> & React.ComponentPropsWithoutRef<"div">;
export const TableLoader = React.forwardRef<HTMLDivElement, TableLoaderProps>(
  ({ pending, className, ...rest }, ref) => {
    return (
      <div {...rest} className={tableLoaderContainer({ pending, className })} ref={ref}>
        <LoadingContainer level="component" className="bg-transparent" size="md" />
      </div>
    );
  },
);

const tableLoaderContainer = tv({
  base: "pointer-events-none",
  variants: {
    pending: {
      true: "w-full h-full absolute inset-0 grid place-items-center",
      false: "hidden",
    },
  },
});
