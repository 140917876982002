import PropTypes from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import apiService from "shared/api/api-service";
import Button from "shared/components/Button";
import ButtonGroup from "shared/components/ButtonGroup";
import Grid from "shared/components/Grid";
import { H4 } from "shared/components/H";
import Modal from "shared/components/Modal";
import { toast } from "shared/components/ds/Toast/Toast";
import Input from "shared/components/forms/Input";
import { formatFormValues } from "shared/util/forms";

const defaultValues = {
  context: "",
};

const mapToFormValues = (status) => ({
  context: status?.context || "",
});

const UpdateStatusModal = ({ modalOpen, modalData, onRequestClose }) => {
  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    mode: "onTouched",
    shouldUnregister: true,
    defaultValues,
  });

  const onSubmit = (values) => {
    const formData = formatFormValues(values);

    apiService
      .patch(
        `${modalData?.endpoint}/${modalData?.jobId || modalData?.userId}/statuses/${modalData?.status?.id}`,
        formData,
      )
      .then(() => {
        onRequestClose(true);
        toast.success("Status updated");
      })
      .catch(() => {
        onRequestClose(false);
        toast.error("Error updating status");
      });
  };

  // Empty the form on modal close
  useEffect(() => {
    if (!modalOpen) {
      reset({ ...defaultValues });
    }

    if (modalData?.status) {
      reset({ ...defaultValues, ...mapToFormValues(modalData.status) });
    }
  }, [modalOpen, modalData, reset]);

  return (
    <Modal
      isOpen={modalOpen === "updateStatus"}
      onRequestClose={() => {
        onRequestClose(false);
      }}
      large
    >
      <H4>Update {modalData?.status?.type} Status Context</H4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid formGrid>
          <Grid.Item>
            <Input
              {...register("context", {
                required: "Please add context to this status",
              })}
              type="text"
              label="Context"
              placeholder="Context"
              errors={errors}
            />
          </Grid.Item>

          <Grid.Item span={2} as={ButtonGroup}>
            <Button type="submit" disabled={!isDirty} $small>
              Update Context
            </Button>

            <Button
              type="button"
              onClick={() => {
                onRequestClose();
              }}
              $theme="secondary"
              $small
            >
              Cancel
            </Button>
          </Grid.Item>
        </Grid>
      </form>
    </Modal>
  );
};

UpdateStatusModal.propTypes = {
  modalOpen: PropTypes.string.isRequired,
  modalData: PropTypes.object,
  onRequestClose: PropTypes.func,
};

export default UpdateStatusModal;
