import * as DialogPrimitive from "@radix-ui/react-dialog";
import { type DialogProps } from "@radix-ui/react-dialog";
import * as CommandPrimitive from "cmdk";
import * as React from "react";
import { Dialog, DialogContent, DialogOverlay, DialogPortal } from "shared/components/ds/Dialog";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { IconLoader } from "shared/components/icons/IconLoader/IconLoader";
import { focusRingStyles } from "shared/styles/focus";
import { twMerge } from "tailwind-merge";

export const Command = CommandPrimitive.Command;

interface CommandDialogProps extends DialogProps {}
export const CommandDialog = ({ children, ...rest }: CommandDialogProps) => {
  return (
    <Dialog {...rest}>
      <DialogPortal>
        <DialogOverlay className="inset-0 block" />
        <DialogContent
          className="data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[13%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[13%] fixed left-1/2 top-[15%] -translate-x-1/2 overflow-clip p-0"
          {...rest}
        >
          {children}
          <DialogPrimitive.Close
            className={focusRingStyles({
              className:
                "ring-offset-ds-bg-foundation absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 disabled:pointer-events-none",
            })}
          >
            <Icon className="h-4 w-4" aria-hidden>
              <IconUse id="close-line" />
            </Icon>
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

interface CommandInputProps extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandInput> {
  loading?: boolean;
}
export const CommandInput = React.forwardRef<React.ElementRef<typeof CommandPrimitive.CommandInput>, CommandInputProps>(
  ({ className, loading = false, ...rest }, ref) => (
    <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
      <div className="mr-2 h-4 w-4 shrink-0 opacity-50">
        {loading ? (
          <IconLoader animate className="h-4 w-4" />
        ) : (
          <Icon className="h-4 w-4">
            <IconUse id="search-line" />
          </Icon>
        )}
      </div>
      <CommandPrimitive.CommandInput
        ref={ref}
        className={twMerge(
          "placeholder:text-ds-text-tertiary flex h-12 w-full rounded-md bg-transparent text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        {...rest}
      />
    </div>
  ),
);
CommandInput.displayName = CommandPrimitive.CommandInput.displayName;

export const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandList>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandList>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandList
    ref={ref}
    className={twMerge("max-h-[300px] overflow-y-auto overflow-x-hidden", className)}
    {...rest}
  />
));
CommandList.displayName = CommandPrimitive.CommandList.displayName;

export const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandEmpty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandEmpty>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandEmpty
    ref={ref}
    className={twMerge("text-ds-text-primary py-6 text-center text-sm", className)}
    {...rest}
  />
));
CommandEmpty.displayName = CommandPrimitive.CommandEmpty.displayName;

export const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandGroup>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandGroup>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandGroup
    ref={ref}
    className={twMerge("text-ds-text-primary overflow-hidden px-2 py-1", className)}
    {...rest}
  />
));
CommandGroup.displayName = CommandPrimitive.CommandGroup.displayName;

export const CommandGroupHeading = React.forwardRef<React.ElementRef<"span">, React.ComponentPropsWithoutRef<"span">>(
  ({ className, ...rest }, ref) => (
    <span
      ref={ref}
      className={twMerge("text-ds-text-tertiary block px-2 py-1.5 text-xs font-medium", className)}
      {...rest}
    />
  ),
);
CommandGroupHeading.displayName = "CommandGroupHeading";

export const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandSeparator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandSeparator>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandSeparator
    ref={ref}
    className={twMerge("bg-ds-stroke-tertiary -mx-1 h-px", className)}
    {...rest}
  />
));
CommandSeparator.displayName = CommandPrimitive.CommandSeparator.displayName;

export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.CommandItem>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.CommandItem>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.CommandItem
    ref={ref}
    className={twMerge(
      "aria-selected:bg-ds-bg-weaker relative flex cursor-default select-none items-center rounded-md px-2 py-3 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className,
    )}
    {...rest}
  />
));
CommandItem.displayName = CommandPrimitive.CommandItem.displayName;
