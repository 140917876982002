import * as SheetPrimitive from "@radix-ui/react-dialog";
import * as React from "react";
import { mergeRefs } from "react-merge-refs";
import { PopoverContainerProvider } from "shared/components/ds/Popover";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { useElementStore } from "shared/stores/element-store";
import { focusRingStyles } from "shared/styles/focus";
import { twMerge } from "tailwind-merge";
import { VariantProps, tv } from "tailwind-variants";

export const Sheet = SheetPrimitive.Root;

export const SheetTrigger = SheetPrimitive.Trigger;

export const SheetClose = SheetPrimitive.Close;

const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...rest }, ref) => (
  <SheetPrimitive.Overlay
    className={twMerge(
      "bg-ds-neutral-900/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50",
      className,
    )}
    {...rest}
    ref={ref}
  />
));
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

interface LayoutSheetProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof layoutSheetContentVariants> {}
export const LayoutSheet = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, LayoutSheetProps>(
  ({ size = "sm", className, children, ...rest }, ref) => {
    const [contentRef, setContentRef] = React.useState<HTMLDivElement | null>(null);
    const layoutSheetRef = useElementStore((state) => state.elements.layoutSheetRef);

    return (
      <PopoverContainerProvider container={contentRef}>
        <SheetPortal container={layoutSheetRef ?? undefined}>
          <SheetOverlay aria-label="Close" className="bg-ds-neutral-900/20">
            <SheetPrimitive.Content
              ref={mergeRefs([ref, setContentRef])}
              className={layoutSheetContentVariants({ size, className })}
              {...rest}
            >
              {children}
              <SheetPrimitive.Close
                className={focusRingStyles({
                  className:
                    "ring-offset-ds-bg-foundation data-[state=open]:bg-ds-bg-foundation fixed right-6 top-6 z-30 h-4 w-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 disabled:pointer-events-none",
                })}
              >
                <Icon className="h-4 w-4">
                  <IconUse id="close-line" />
                </Icon>
                <span className="sr-only">Close</span>
              </SheetPrimitive.Close>
            </SheetPrimitive.Content>
          </SheetOverlay>
        </SheetPortal>
      </PopoverContainerProvider>
    );
  },
);
LayoutSheet.displayName = "LayoutSheet";

const layoutSheetContentVariants = tv({
  base: "fixed gap-4 flex outline-none flex-col overflow-y-auto bg-ds-bg-foundation shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 w-full top-2 rounded-tl-3xl rounded-bl-3xl right-0 h-full border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right",
  variants: {
    size: {
      sm: "sm:max-w-sm",
      md: "sm:max-w-[530px]",
      lg: "sm:max-w-[730px]",
    },
  },
  defaultVariants: {
    size: "sm",
    side: "right",
  },
});

export const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={twMerge(
      "border-ds-stroke-tertiary bg-ds-bg-foundation sticky inset-0 z-20 flex flex-col gap-2 border-b p-6 outline-none",
      className,
    )}
    {...props}
  />
);
SheetHeader.displayName = "SheetHeader";

export const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...rest }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={twMerge("text-ds-text-primary m-0 text-lg font-medium", className)}
    {...rest}
  />
));
SheetTitle.displayName = SheetPrimitive.Title.displayName;

export const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...rest }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={twMerge("text-ds-text-secondary m-0 text-sm", className)}
    {...rest}
  />
));
SheetDescription.displayName = SheetPrimitive.Description.displayName;
