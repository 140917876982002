/**
 * Converts an ErrorLike to a proper Error if needed, copying all properties
 *
 * @param value An Error, object with ErrorLike properties, or other value.
 * @see https://github.com/sourcegraph/sourcegraph/tree/main/client/web
 */
export const asError = (value: unknown) => {
  if (value instanceof Error) {
    return value;
  }
  if (isErrorLike(value)) {
    return Object.assign(new Error(value.message), value);
  }
  return new Error(String(value));
};

interface ErrorLike {
  message: string;
  name?: string;
}

export function isWebpackChunkError(value: unknown) {
  return isErrorLike(value) && (value.name === "ChunkLoadError" || /loading css chunk/gi.test(value.message));
}

const isErrorLike = (value: unknown): value is ErrorLike =>
  typeof value === "object" && value !== null && ("stack" in value || "message" in value) && !("__typename" in value);
