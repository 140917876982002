const config = {
  api: process.env.REACT_APP_API_BASE,
  socketURL: process.env.REACT_APP_SOCKET_URL,
  cognito: {
    region: process.env.REACT_APP_COGNITO_REGION,
    poolID: process.env.REACT_APP_COGNITO_POOL_ID,
    appID: process.env.REACT_APP_COGNITO_APP_ID,
  },
  maintenanceMode: process.env.REACT_APP_MAINTENANCE_MODE,
  maintenanceModeCode: process.env.REACT_APP_MAINTENANCE_MODE_CODE,
  elastic: {
    url: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
    serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
    serviceVersion: "0.1.1",
    environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
} as const;

export default config;
