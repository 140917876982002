import { SAVE_ROLE_FORM_ID, SaveRoleForm } from "participant/components/SaveRoleForm";
import * as React from "react";
import { ResponsiveDialogLayout } from "shared/components/ResponsiveDialogLayout";
import { Button } from "shared/components/ds/Button";
import { DialogClose, DialogFooter } from "shared/components/ds/Dialog";
import { DrawerClose, DrawerFooter } from "shared/components/ds/Drawer";
import { toast } from "shared/components/ds/Toast/Toast";

export function SaveRoleDialog({ children }: { children?: React.ReactNode }) {
  return (
    <ResponsiveDialogLayout
      title="Save Role"
      description="Add a new role to your saved list"
      dialogContent={(setIsOpen) => {
        return (
          <SaveRoleForm
            onSaveSuccess={() => {
              setIsOpen(false);
            }}
            onSaveFailure={() => {
              toast.error("Failed to save role");
            }}
          >
            {(isSubmitting) => {
              return (
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DialogClose>
                  <Button
                    variant="secondary"
                    form={SAVE_ROLE_FORM_ID}
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </DialogFooter>
              );
            }}
          </SaveRoleForm>
        );
      }}
      drawerContent={(setIsOpen) => {
        return (
          <SaveRoleForm
            onSaveSuccess={() => {
              setIsOpen(false);
            }}
            onSaveFailure={() => {
              toast.error("Failed to save role");
            }}
          >
            {(isSubmitting) => {
              return (
                <DrawerFooter className="px-0 pb-0 pt-6">
                  <DrawerClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DrawerClose>
                  <Button
                    variant="secondary"
                    form={SAVE_ROLE_FORM_ID}
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Save
                  </Button>
                </DrawerFooter>
              );
            }}
          </SaveRoleForm>
        );
      }}
    >
      {children}
    </ResponsiveDialogLayout>
  );
}
