import * as Sentry from "@sentry/react";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { NestedNotFound, NotFound } from "shared/components/NotFound";
import { Button } from "shared/components/ds/Button";
import { Icon, IconSprite, IconUse } from "shared/components/ds/icons/Icon";
import { heading } from "shared/styles/heading";
import { text } from "shared/styles/text";
import { asError, isWebpackChunkError } from "shared/util/errorHelpers";

export function TopLevelRouteErrorHandler() {
  const routeError = useRouteError();

  if (isRouteErrorResponse(routeError)) {
    return (
      <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
        <KnownRouteError error={routeError} />
      </div>
    );
  }

  return (
    <div className="content-container flex min-h-[calc(100dvh-var(--siteheader-height))] items-center justify-center">
      <UnknownRouteError error={routeError} />
    </div>
  );
}

function UnknownRouteError({ error }: { error: unknown }) {
  const transformedError = React.useMemo(() => {
    return asError(error);
  }, [error]);

  React.useEffect(() => {
    Sentry.withScope((scope) => {
      scope.setTag("errorBoundary", "RouteError");
      Sentry.captureException(transformedError);
    });
  }, [transformedError]);

  return <ErrorHandler error={transformedError} />;
}

export function NestedRouteErrorHandler() {
  const routeError = useRouteError();

  if (isRouteErrorResponse(routeError)) {
    return <KnownRouteError error={routeError} nested />;
  }

  return (
    <div className="flex w-full items-center justify-center py-10">
      <UnknownRouteError error={routeError} />
    </div>
  );
}

function KnownRouteError({ error, nested = false }: { error: unknown; nested?: boolean }) {
  const isRouteError = isRouteErrorResponse(error);
  const translatedError = React.useMemo(() => {
    if (isRouteError) {
      return new Error(error.data ? error.data : `${error.status} ${error.statusText}`);
    }
    return new Error("An unknown error occurred");
  }, [error, isRouteError]);

  if (!isRouteError) {
    return <GeneralRouteErrorMessage error={translatedError} />;
  }

  switch (error.status) {
    case 404: {
      return nested ? <NestedNotFound /> : <NotFound />;
    }
    default: {
      return <GeneralRouteErrorMessage error={translatedError} />;
    }
  }
}

interface ErrorBoundaryMessageProps {
  error: Error;
}
function ErrorHandler({ error }: ErrorBoundaryMessageProps) {
  if (isWebpackChunkError(error)) {
    return <ChunkLoadErrorMessage />;
  }

  return <GeneralRouteErrorMessage error={error} />;
}

function ChunkLoadErrorMessage() {
  return (
    <div className="stack-y-6 w-full max-w-prose">
      <div className="stack-y-4 items-center">
        <Icon className="h-8 w-8 ">
          <IconUse id="loop-right-line" />
        </Icon>
        <h1
          className={heading({
            variant: { initial: "24", md: "30" },
            className: "text-center",
          })}
        >
          Our app has been updated
        </h1>

        <p
          className={text({
            variant: "16",
            color: "secondary",
            align: "center",
            className: "text-balance",
          })}
        >
          The BreakLine app has been updated. Please refresh the page or click the button below to get the latest
          version.
        </p>
      </div>
      <div className="mx-auto">
        <Button size="lg" onClick={hardReload}>
          Reload
        </Button>
      </div>
    </div>
  );
}

function GeneralRouteErrorMessage({ error }: { error: Error }) {
  return (
    <div className="stack-y-6 w-full max-w-prose">
      <div className="stack-y-4 items-center">
        <Icon className="h-8 w-8 ">
          <IconUse id="error-warning-fill" />
        </Icon>
        <h1
          className={heading({
            variant: { initial: "24", md: "30" },
            className: "text-center",
          })}
        >
          Unable to load page
        </h1>

        <p
          className={text({
            variant: "16",
            color: "secondary",
            align: "center",
          })}
        >
          An error has occurred while loading this page.
        </p>
      </div>
      <div className="mx-auto">
        <Button size="lg" onClick={hardReload}>
          Reload
        </Button>
      </div>
      <details>
        <summary
          className={text({
            variant: "16",
            color: "secondary",
            weight: "medium",
            align: "center",
          })}
        >
          View Error
        </summary>
        <pre className="text-ds-text-secondary max-w-full overflow-scroll whitespace-pre-wrap rounded-md p-2 font-mono text-sm">
          {error.message}
        </pre>
      </details>
    </div>
  );
}

export function RootError({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => (
        <div className="flex min-h-[100dvh] items-center justify-center">
          <ErrorHandler error={error} />
          <IconSprite />
        </div>
      )}
    >
      {children}
    </ErrorBoundary>
  );
}

function hardReload(): void {
  window.location.reload();
}
