export const TEAM_EXPERIENCE_PREFIX = "hq" as const;

export const DASHBOARD_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}` as const,
} as const;

export const INTERIVEW_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/interviews` as const,
  detail: (id: string) => `${INTERIVEW_PATHS.root}/${id}` as const,
  edit: (id: string) => `${INTERIVEW_PATHS.root}/${id}/edit` as const,
  create: () => `${INTERIVEW_PATHS.root}/create` as const,
} as const;

export const JOB_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/jobs` as const,
  detail: (id: string) => `${JOB_PATHS.root}/${id}` as const,
  interviews: (id: string) => `${JOB_PATHS.detail(id)}/interviews` as const,
  insight: (id: string) => `${JOB_PATHS.detail(id)}/insight` as const,
  edit: (id: string) => `${JOB_PATHS.detail(id)}/edit` as const,
  editStatuses: (id: string) => `${JOB_PATHS.edit(id)}/statuses` as const,
  create: () => `${JOB_PATHS.root}/create` as const,
  gamePlan: () => `${JOB_PATHS.root}/game-plan` as const,
  gamePlanDetail: (id: string) => `${JOB_PATHS.gamePlan()}/${id}` as const,
} as const;

export const PIPELINE_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/pipeline-plus` as const,
  interviews: () => `${PIPELINE_PATHS.root}/interviews` as const,
} as const;

export const PARTNER_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/partners` as const,
  all: () => `${PARTNER_PATHS.root}/all` as const,
  create: () => `${PARTNER_PATHS.root}/create` as const,
  detail: (id: string) => `${PARTNER_PATHS.root}/${id}` as const,
  edit: (id: string) => `${PARTNER_PATHS.detail(id)}/edit` as const,
  preview: (id: string) => `${PARTNER_PATHS.detail(id)}/preview` as const,
  hired: (id: string) => `${PARTNER_PATHS.detail(id)}/hired` as const,
  contactList: (id: string) => `${PARTNER_PATHS.detail(id)}/contact-list` as const,

  contactListCreate: (id: string) => {
    return `${PARTNER_PATHS.contactList(id)}/create` as const;
  },
  contact: (partnerId: string, recruiterId: string) => {
    return `${PARTNER_PATHS.contactList(partnerId)}/${recruiterId}` as const;
  },
  contactEdit: (partnerId: string, recruiterId: string) => {
    return `${PARTNER_PATHS.contact(partnerId, recruiterId)}/edit` as const;
  },
} as const;

export const PARTICIPANT_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/participants` as const,
  detail: (id: string) => `${PARTICIPANT_PATHS.root}/${id}` as const,
  opportunities: (id: string) => {
    return `${PARTICIPANT_PATHS.detail(id)}/opportunities` as const;
  },
  coaching: (id: string) => {
    return `${PARTICIPANT_PATHS.detail(id)}/coaching` as const;
  },
  statuses: (id: string) => {
    return `${PARTICIPANT_PATHS.detail(id)}/statuses` as const;
  },
  saved: (id: string) => {
    return `${PARTICIPANT_PATHS.detail(id)}/saved` as const;
  },
  edit: (id: string) => `${PARTICIPANT_PATHS.root}/${id}/edit` as const,
} as const;

export const SHORTLIST_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/shortlist` as const,
} as const;

export const HIRED_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/hired` as const,
} as const;

export const CONTENT_LIBRARY_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/content-library` as const,
  detail: (id: string) => `${CONTENT_LIBRARY_PATHS.root}/${id}` as const,
  create: () => `${CONTENT_LIBRARY_PATHS.root}/create` as const,
  edit: (id: string) => `${CONTENT_LIBRARY_PATHS.detail(id)}/edit` as const,
} as const;

export const ASSIGNMENT_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/assignments` as const,
  detail: (id: string) => `${ASSIGNMENT_PATHS.root}/${id}` as const,
  submissions: (id: string) => `${ASSIGNMENT_PATHS.detail(id)}/submissions` as const,
  submission: (id: string) => `${ASSIGNMENT_PATHS.root}/submissions/${id}` as const,
  edit: (id: string) => `${ASSIGNMENT_PATHS.detail(id)}/edit` as const,
  create: () => `${ASSIGNMENT_PATHS.root}/create` as const,
  preview: (id: string) => `${ASSIGNMENT_PATHS.detail(id)}/preview` as const,
} as const;

export const SURVEYS_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/surveys` as const,
  detail: (id: string) => `${SURVEYS_PATHS.root}/${id}` as const,
  create: () => `${SURVEYS_PATHS.root}/create` as const,
  edit: (id: string) => `${SURVEYS_PATHS.detail(id)}/edit` as const,
  preview: (id: string) => `${SURVEYS_PATHS.detail(id)}/preview` as const,
  submissions: (id: string) => `${SURVEYS_PATHS.detail(id)}/submissions` as const,

  submission: (id: string) => `${SURVEYS_PATHS.root}/submissions/${id}` as const,
} as const;

export const EVENTS_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/events` as const,
  detail: (id: string) => `${EVENTS_PATHS.root}/${id}` as const,
  type: (type: string) => `${EVENTS_PATHS.root}/type/${type}` as const,
  create: () => `${EVENTS_PATHS.root}/create` as const,
  edit: (id: string) => `${EVENTS_PATHS.detail(id)}/edit` as const,
  duplicate: (id: string) => `${EVENTS_PATHS.detail(id)}/create` as const,
} as const;

export const AUTOMATION_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/automation` as const,
  partner: () => {
    return `${AUTOMATION_PATHS.root}/partner/resource` as const;
  },
  function: () => {
    return `${AUTOMATION_PATHS.root}/function/resource` as const;
  },
  module: () => {
    return `${AUTOMATION_PATHS.root}/module/resource` as const;
  },
  stage: () => {
    return `${AUTOMATION_PATHS.root}/stage/survey` as const;
  },
  event: () => {
    return `${AUTOMATION_PATHS.root}/event/survey` as const;
  },
  platformEvent: () => {
    return `${AUTOMATION_PATHS.root}/platform-event/survey` as const;
  },
} as const;

export const TAGS_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/tags` as const,
  skills: () => {
    return `${TAGS_PATHS.root}/skills` as const;
  },
  tracks: () => {
    return `${TAGS_PATHS.root}/tracks` as const;
  },
  locations: () => {
    return `${TAGS_PATHS.root}/locations` as const;
  },
  jobFunctions: () => {
    return `${TAGS_PATHS.root}/job-functions` as const;
  },
  contentCategories: () => {
    return `${TAGS_PATHS.root}/content-categories` as const;
  },
  eventCategories: () => {
    return `${TAGS_PATHS.root}/event-categories` as const;
  },
  selfIdentifications: () => {
    return `${TAGS_PATHS.root}/self-identifications` as const;
  },
  segments: () => {
    return `${TAGS_PATHS.root}/segments` as const;
  },
  partnerTypes: () => {
    return `${TAGS_PATHS.root}/partner-types` as const;
  },
  partnerStages: () => {
    return `${TAGS_PATHS.root}/partner-stages` as const;
  },
  partnerSpaces: () => {
    return `${TAGS_PATHS.root}/partner-spaces` as const;
  },
  recruiterTypes: () => {
    return `${TAGS_PATHS.root}/recruiter-types` as const;
  },
  recruiterStatuses: () => {
    return `${TAGS_PATHS.root}/recruiter-statuses` as const;
  },
} as const;

export const TEAM_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/team` as const,
} as const;

export const NOTIFICATION_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/notifications` as const,
  tasks: () => `${NOTIFICATION_PATHS.root}/tasks` as const,
  outgoing: () => `${NOTIFICATION_PATHS.root}/outgoing` as const,
  manager: () => `${NOTIFICATION_PATHS.root}/manager` as const,
} as const;

export const ACCOUNT_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/account` as const,
  notifications: () => `${ACCOUNT_PATHS.root}/notifications` as const,
} as const;

export const PLAYGROUND_PATHS = {
  root: `/${TEAM_EXPERIENCE_PREFIX}/playground` as const,
} as const;
