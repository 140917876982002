import { QueryClient } from "@tanstack/react-query";
import { redirect } from "react-router-dom";
import { USER_OPTIONS } from "shared/api/user/user";

export async function onboardedGuardLoader(queryClient: QueryClient) {
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

  if (!user?.codeOfConduct) {
    return redirect(`/onboarding`);
  }

  return null;
}
