import * as React from "react";
import { Button, ButtonIcon } from "shared/components/ds/Button";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { twMerge } from "tailwind-merge";
import { VariantProps, tv } from "tailwind-variants";

type TableProps = React.HTMLAttributes<HTMLTableElement> &
  VariantProps<typeof tableStyles> & {
    type?: "default" | "sub";
  };
export const Table = React.forwardRef<HTMLTableElement, TableProps>(({ className, theme, type, ...props }, ref) => {
  const { container } = tableStyles({ theme });

  return (
    <TableContext.Provider value={{ theme }}>
      {type === "sub" ? (
        <div className="bg-ds-bg-weaker p-8">
          <div className={container({ className: "overflow-clip" })}>
            <table
              ref={ref}
              className={twMerge("w-full caption-bottom border-collapse text-sm", className)}
              {...props}
            />
          </div>
        </div>
      ) : (
        <div className={container({ className: "relative overflow-auto" })}>
          <table
            ref={ref}
            className={twMerge("relative w-full caption-bottom border-collapse text-sm", className)}
            {...props}
          />
        </div>
      )}
    </TableContext.Provider>
  );
});
Table.displayName = "Table";

type TableHeaderProps = React.HTMLAttributes<HTMLTableSectionElement> & VariantProps<typeof tableStyles>;
export const TableHeader = React.forwardRef<HTMLTableSectionElement, TableHeaderProps>(
  ({ className, theme, ...props }, ref) => {
    const context = useTableContext();
    const { header } = tableStyles({
      theme: theme ?? context.theme,
    });

    return <thead ref={ref} className={header({ className })} {...props} />;
  },
);
TableHeader.displayName = "TableHeader";

export const TableBody = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tbody ref={ref} className={twMerge("[&>tr:last-child]:border-0", className)} {...props} />
  ),
);
TableBody.displayName = "TableBody";

export const TableFooter = React.forwardRef<HTMLTableSectionElement, React.HTMLAttributes<HTMLTableSectionElement>>(
  ({ className, ...props }, ref) => (
    <tfoot
      ref={ref}
      className={twMerge("border-ds-stroke-tertiary border-t font-medium [&>tr]:last:border-b-0", className)}
      {...props}
    />
  ),
);
TableFooter.displayName = "TableFooter";

type TableRowProps = React.HTMLAttributes<HTMLTableRowElement> & VariantProps<typeof tableStyles>;
export const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(({ className, theme, ...props }, ref) => {
  const context = useTableContext();
  const { row } = tableStyles({
    theme: theme ?? context.theme,
  });

  return <tr ref={ref} className={row({ className })} {...props} />;
});
TableRow.displayName = "TableRow";

export type TableHeadProps = React.ThHTMLAttributes<HTMLTableCellElement> & VariantProps<typeof tableStyles>;
export const TableHead = React.forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ className, theme, ...props }, ref) => {
    const context = useTableContext();
    const { headerCell } = tableStyles({
      theme: theme ?? context.theme,
    });

    return <th ref={ref} className={headerCell({ className })} {...props} />;
  },
);
TableHead.displayName = "TableHead";

type TableHeadActionProps = React.ComponentPropsWithoutRef<typeof Button>;

export const TableHeadAction = React.forwardRef<React.ComponentRef<typeof Button>, TableHeadActionProps>(
  ({ className, suffix, ...rest }, ref) => {
    return (
      <Button
        ref={ref}
        variant="ghost"
        size="sm"
        className={twMerge(
          "hover:bg-ds-bg-foundation active:bg-ds-bg-foundation text-inherit [font:inherit] focus-visible:ring-0",
          className,
        )}
        suffix={
          suffix ?? (
            <ButtonIcon className="h-3 w-3">
              <IconUse id="expand-up-down-line" />
            </ButtonIcon>
          )
        }
        {...rest}
      />
    );
  },
);
TableHeadAction.displayName = "TableHeadAction";

export const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={twMerge(
        '[&:has([data-state=checked])]:before:bg-ds-primary-base relative isolate px-3 py-4 align-middle [&:has([data-state=checked])]:before:absolute [&:has([data-state=checked])]:before:inset-0 [&:has([data-state=checked])]:before:h-full [&:has([data-state=checked])]:before:w-[2px] [&:has([data-state=checked])]:before:content-[""] [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
        className,
      )}
      {...props}
    />
  ),
);
TableCell.displayName = "TableCell";

type TableCaptionProps = React.HTMLAttributes<HTMLTableCaptionElement> & VariantProps<typeof tableStyles>;
export const TableCaption = React.forwardRef<HTMLTableCaptionElement, TableCaptionProps>(
  ({ className, theme, ...props }, ref) => {
    const context = useTableContext();
    const { caption } = tableStyles({
      theme: theme ?? context.theme,
    });
    return <caption ref={ref} className={caption({ className })} {...props} />;
  },
);
TableCaption.displayName = "TableCaption";

const tableStyles = tv({
  slots: {
    container: "w-full",
    header: "isolate relative z-10",
    body: "[&_tr:last-child]:border-0",
    row: "border-b transition-colors data-[state=selected]:bg-ds-neutral-50",
    headerCell:
      "h-10 whitespace-nowrap overflow-hidden bg-ds-bg-weaker px-3 text-left align-middle font-medium text-ds-text-secondary [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
    caption: "pt-4 text-sm",
  },
  variants: {
    theme: {
      default: {
        row: "border-ds-stroke-tertiary",
        header: "[&>tr]:border-0",
        headerCell: "first-of-type:rounded-l-md last-of-type:rounded-r-md ",
        caption: "text-ds-text-secondary",
      },
      layer1: {
        header: "[&>tr]:border-b [&>tr]:border-ds-stroke-secondary",
        container: "rounded-md border border-ds-stroke-secondary shadow",
        row: "bg-ds-bg-foundation border-ds-stroke-secondary",
        headerCell: "first-of-type:rounded-none last-of-type:rounded-none",
        caption: "bg-ds-bg-foundation text-ds-text-primary pb-4",
      },
    },
  },
  defaultVariants: {
    theme: "default",
  },
});

const TableContext = React.createContext<VariantProps<typeof tableStyles>>({
  theme: "default",
});

function useTableContext() {
  return React.useContext(TableContext);
}

export const TableSelectionWidget = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div">>(
  ({ className, ...rest }, ref) => {
    return (
      <div
        className={twMerge(
          "border-ds-neutral-400 bg-ds-bg-strong text-ds-neutral-200 flex flex-wrap items-center justify-center gap-2 rounded-full border-2 px-3 py-1.5 text-sm shadow-md",
          className,
        )}
        ref={ref}
        {...rest}
      />
    );
  },
);

export const TableSelectionWidgetActions = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div">>(
  ({ className, ...rest }, ref) => {
    return (
      <div className={twMerge("flex flex-wrap items-center justify-center gap-1", className)} ref={ref} {...rest} />
    );
  },
);

export const TableSelectionWidgetClearAction = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<"button">
>(({ className, children, ...rest }, ref) => {
  return (
    <span className="border-ds-neutral-400 focus-within:ring-ds-neutral-300 focus-within:ring-offset-ds-neutral-600 flex h-7 items-center overflow-hidden rounded border border-dashed text-sm transition-colors focus-within:ring-1 focus-within:ring-offset-1">
      <span className="whitespace-nowrap px-2">{children}</span>
      <button
        ref={ref}
        className={twMerge(
          "border-ds-neutral-400 hover:bg-ds-neutral-600/80 grid h-7 w-7 shrink-0 place-items-center border-l border-dashed transition-colors focus-visible:shadow-none",
          className,
        )}
        aria-label="Clear selection"
        {...rest}
      >
        <Icon className="h-4 w-4" aria-hidden>
          <IconUse id="close-line" />
        </Icon>
      </button>
    </span>
  );
});

interface TableSelectionWidgetActionProps
  extends React.ComponentPropsWithoutRef<"button">,
    VariantProps<typeof tableSelectionWidgetActionStyles> {}
export const TableSelectionWidgetAction = React.forwardRef<HTMLButtonElement, TableSelectionWidgetActionProps>(
  ({ className, variant, ...rest }, ref) => {
    return (
      <button
        className={tableSelectionWidgetActionStyles({
          variant,
          className,
        })}
        ref={ref}
        {...rest}
      />
    );
  },
);

const tableSelectionWidgetActionStyles = tv({
  base: "rounded px-2 whitespace-nowrap text-sm h-7 transition-colors flex gap-2 items-center",
  variants: {
    variant: {
      default:
        "text-ds-neutral-300 border border-ds-neutral-400 bg-ds-neutral-600 hover:bg-ds-neutral-600/80 focus-visible:ring-1 focus-visible:ring-ds-neutral-300 ring-offset-1 ring-offset-ds-neutral-600",
      destructive: "bg-ds-red-darker text-ds-red-lighter hover:bg-ds-red-darker/80",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});
