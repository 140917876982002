import { queryOptions } from "@tanstack/react-query";
import api from "shared/api/api";
import { Params } from "shared/api/types/params";
import { INote } from "shared/types/note.types";
import { PaginatedResponse } from "shared/types/pagination.types";
import { createInfiniteQuery } from "shared/util/createInfiniteQuery";

export const INTERVIEWS_QUERY_OPTIONS = {
  all: ["interviews"] as const,
  detail: (interviewId: string | null) => {
    return queryOptions({
      queryKey: [...INTERVIEWS_QUERY_OPTIONS.all, interviewId] as const,
      queryFn: async ({ signal }) => {
        if (interviewId == null) {
          return null;
        }
        const response = await api.get(`/interviews/${interviewId}`, {
          signal,
        });

        return response.data;
      },
    });
  },
  list: (params: Params) => {
    return queryOptions({
      queryKey: [...INTERVIEWS_QUERY_OPTIONS.all, params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get("/interviews", {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  alignmentScoresList: (interviewId: string) => {
    return queryOptions({
      queryKey: [...INTERVIEWS_QUERY_OPTIONS.detail(interviewId).queryKey, "alignmentScores"] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<InterviewAlignmentCategory>>(
          `/interviews/${interviewId}/alignment-scores`,
          {
            params: {
              skip: 0,
              limit: 100,
            },
            signal,
          },
        );

        return response.data;
      },
    });
  },
  alignmentNotesKey: (interviewId: string) => {
    return [...INTERVIEWS_QUERY_OPTIONS.detail(interviewId).queryKey, "alignmentNotes"] as const;
  },
  alignmentNotesList: (
    interviewId: string,
    params: Params = {
      params: {
        skip: 0,
        limit: 100,
      },
    },
  ) => {
    return queryOptions({
      queryKey: [...INTERVIEWS_QUERY_OPTIONS.alignmentNotesKey(interviewId), params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<INote>>(`/interviews/${interviewId}/alignment-notes`, {
          ...params,
          signal,
        });

        return response.data;
      },
      staleTime: 60 * 1000, // 1min
    });
  },
  roundTypesList: (params: Params) => {
    return queryOptions({
      queryKey: [...INTERVIEWS_QUERY_OPTIONS.all, "roundTypes", params] as const,
      queryFn: async ({ signal }) => {
        const response = await api.get<PaginatedResponse<InterviewRoundType>>(`/interview-round-types`, {
          ...params,
          signal,
        });

        return response.data;
      },
    });
  },
  closingNotesList: (interviewId: string | null) => {
    return queryOptions({
      queryKey: [...INTERVIEWS_QUERY_OPTIONS.detail(interviewId).queryKey, "closingNotes"] as const,
      queryFn: async ({ signal }) => {
        if (interviewId == null) {
          return null;
        }

        const response = await api.get<PaginatedResponse<INote>>(`/interviews/${interviewId}/closing-notes`, {
          params: {
            skip: 0,
            limit: 100,
          },
          signal,
        });

        return response.data;
      },
    });
  },
};

interface InterviewRoundType {
  id: number;
}

interface Score {
  id: number;
  score: number;
}
interface InterviewAlignmentCategory {
  id: number;
  name: string;
  description: string | null;
  active: boolean;
  score?: Score;
}

export function useInfiniteLatestStageFilter(params: Record<string, unknown>) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get(`/interview-round-types`, {
        ...params,
        signal,
      });

      return response.data;
    },
    queryKeyFn: (params: Params) => {
      return INTERVIEWS_QUERY_OPTIONS.roundTypesList(params).queryKey;
    },
  })({
    params: {
      ...params,
      orderBy: "rank",
      orderDir: "asc",
      published: true,
    },
  });
}
