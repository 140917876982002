import { infiniteQueryOptions } from "@tanstack/react-query";
import api from "shared/api/api";
import { Params } from "shared/api/types/params";
import { createInfiniteQuery } from "shared/util/createInfiniteQuery";

const TRACKS_KEYS = {
  all: ["tracks"] as const,
  filter: (params: Params) => [...TRACKS_KEYS.all, "filter", params] as const,
  list: (params: Params) => [...TRACKS_KEYS.all, "list", params] as const,
};

export function useInfiniteTracksFilter(params: Record<string, unknown>) {
  return createInfiniteQuery({
    fetchFn: async (params, { signal }) => {
      const response = await api.get(`/tracks`, {
        ...params,
        signal,
      });

      return response.data;
    },
    queryKeyFn: (params) => TRACKS_KEYS.filter(params),
  })({
    params,
  });
}

export const TRACKS_QUERY_OPTIONS = {
  infiniteTracksList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/tracks`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: TRACKS_KEYS.list({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
    });
  },
};
