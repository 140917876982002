import { useSuspenseQuery } from "@tanstack/react-query";
import {
  EDIT_SAVED_EXTERNAL_ROLE_FORM_ID,
  EditSavedExternalRoleForm,
} from "participant/components/EditSavedExternalRoleForm";
import * as React from "react";
import { PARTICIPANT_QUERIES } from "shared/api/participants";
import { USER_OPTIONS } from "shared/api/user/user";
import { ResponsiveDialogLayout } from "shared/components/ResponsiveDialogLayout";
import { CompanyAvatar } from "shared/components/ds/Avatar";
import { Button } from "shared/components/ds/Button";
import { DialogClose, DialogFooter } from "shared/components/ds/Dialog";
import { DrawerClose, DrawerFooter } from "shared/components/ds/Drawer";
import { toast } from "shared/components/ds/Toast/Toast";
import { heading } from "shared/styles/heading";
import { text } from "shared/styles/text";
import { EDIT_SAVED_INTERNAL_ROLE_FORM_ID, EditSavedInternalRoleForm } from "./EditSavedInternalRoleForm";

export function EditSavedInternalRoleDialog({
  savedRoleId,
  children,
}: {
  savedRoleId: number;
  children: React.ReactNode;
}) {
  return (
    <ResponsiveDialogLayout
      title="Edit Role"
      description="Modify the details of your saved role."
      dialogContent={(setIsOpen) => {
        return (
          <InternalRoleDetails savedRoleId={savedRoleId}>
            <EditSavedInternalRoleForm
              savedRoleId={savedRoleId}
              onEditSuccess={() => {
                setIsOpen(false);
              }}
              onEditFailure={() => {
                toast.error("Failed to update saved role");
              }}
            >
              {(isSubmitting) => {
                return (
                  <DialogFooter>
                    <DialogClose asChild>
                      <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Button
                      variant="secondary"
                      form={EDIT_SAVED_INTERNAL_ROLE_FORM_ID}
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Update
                    </Button>
                  </DialogFooter>
                );
              }}
            </EditSavedInternalRoleForm>
          </InternalRoleDetails>
        );
      }}
      drawerContent={(setIsOpen) => {
        return (
          <InternalRoleDetails savedRoleId={savedRoleId}>
            <EditSavedInternalRoleForm
              savedRoleId={savedRoleId}
              onEditSuccess={() => {
                setIsOpen(false);
              }}
              onEditFailure={() => {
                toast.error("Failed to update saved role");
              }}
            >
              {(isSubmitting) => {
                return (
                  <DrawerFooter className="px-0 pb-0 pt-6">
                    <DrawerClose asChild>
                      <Button variant="outline">Cancel</Button>
                    </DrawerClose>
                    <Button
                      variant="secondary"
                      form={EDIT_SAVED_INTERNAL_ROLE_FORM_ID}
                      type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      Update
                    </Button>
                  </DrawerFooter>
                );
              }}
            </EditSavedInternalRoleForm>
          </InternalRoleDetails>
        );
      }}
    >
      {children}
    </ResponsiveDialogLayout>
  );
}

export function EditSavedExternalRoleDialog({
  savedRoleId,
  children,
}: {
  savedRoleId: number;
  children?: React.ReactNode;
}) {
  return (
    <ResponsiveDialogLayout
      title="Edit Role"
      description="Modify the details of your saved role."
      dialogContent={(setIsOpen) => {
        return (
          <EditSavedExternalRoleForm
            savedRoleId={savedRoleId}
            onEditSuccess={() => {
              setIsOpen(false);
            }}
            onEditFailure={() => {
              toast.error("Failed to update saved role");
            }}
          >
            {(isSubmitting) => {
              return (
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DialogClose>
                  <Button
                    variant="secondary"
                    form={EDIT_SAVED_EXTERNAL_ROLE_FORM_ID}
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Update
                  </Button>
                </DialogFooter>
              );
            }}
          </EditSavedExternalRoleForm>
        );
      }}
      drawerContent={(setIsOpen) => {
        return (
          <EditSavedExternalRoleForm
            savedRoleId={savedRoleId}
            onEditSuccess={() => {
              setIsOpen(false);
            }}
            onEditFailure={() => {
              toast.error("Failed to update saved role");
            }}
          >
            {(isSubmitting) => {
              return (
                <DrawerFooter className="px-0 pb-0 pt-6">
                  <DrawerClose asChild>
                    <Button variant="outline">Cancel</Button>
                  </DrawerClose>
                  <Button
                    variant="secondary"
                    form={EDIT_SAVED_EXTERNAL_ROLE_FORM_ID}
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Update
                  </Button>
                </DrawerFooter>
              );
            }}
          </EditSavedExternalRoleForm>
        );
      }}
    >
      {children}
    </ResponsiveDialogLayout>
  );
}

function InternalRoleDetails({ savedRoleId, children }: { savedRoleId: number; children?: React.ReactNode }) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const { data: savedRole } = useSuspenseQuery(
    PARTICIPANT_QUERIES.savedRoleDetail<"internal">(user.id.toString(), savedRoleId),
  );
  const { company, job } = savedRole;
  const companyName = typeof company === "string" ? company : company.name;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-2 px-3 py-2">
        <CompanyAvatar alt={`${company} logo`} fallback={[companyName]} size="base" logo={company?.logo?.location} />
        <div className="flex flex-col">
          <h3 className={heading({ variant: "16" })}>{job.title}</h3>
          <p className={text({ variant: "14" })}>{companyName}</p>
        </div>
      </div>
      {children}
    </div>
  );
}
