import UnstyledList from "shared/components/UnstyledList";
import styled from "styled-components";

const DownshiftStyles = {};

DownshiftStyles.Popover = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background: var(--white);
  z-index: 11;
  box-shadow: var(--tooltip-shadow);
  border-radius: var(--border-radius);
  width: 100%;
`;

DownshiftStyles.SearchMenu = styled(UnstyledList)`
  max-height: 200px;
  overflow: auto;
  margin: 0;
`;

DownshiftStyles.MenuItem = styled.li`
  color: ${(props) => (props.highlight ? "var(--primary-green)" : "var(--black)")};
  padding: 6px 8px;

  &:first-of-type {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
`;

export default DownshiftStyles;
