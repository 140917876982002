import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";
import { twMerge } from "tailwind-merge";

/**
 * With this component, if the user moves the cursor between tooltip group, the hide delay is set to 0, this makes the
 * next tooltip appear immediately.
 */
export const TooltipGroup = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<"div">>(
  ({ className, onMouseEnter, onMouseLeave, ...rest }, ref) => {
    const [showInstant, setShowInstant] = React.useState(false);
    const delay = showInstant ? 0 : 700;

    return (
      <TooltipGroupContext.Provider value={{ delay }}>
        <div
          ref={ref}
          onMouseEnter={(e) => {
            setShowInstant(true);
            onMouseEnter?.(e);
          }}
          onMouseLeave={(e) => {
            setShowInstant(false);
            onMouseLeave?.(e);
          }}
          className={twMerge("h-fit", className)}
          {...rest}
        />
      </TooltipGroupContext.Provider>
    );
  },
);
TooltipGroup.displayName = "TooltipGroup";

const TooltipGroupContext = React.createContext<{ delay: number } | null>(null);

export function TooltipProvider({
  delayDuration,
  ...rest
}: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider>) {
  const { delay } = React.useContext(TooltipGroupContext) ?? {};

  return <TooltipPrimitive.Provider delayDuration={delayDuration ?? delay} {...rest} />;
}

export const Tooltip = TooltipPrimitive.Root;

export const TooltipTrigger = TooltipPrimitive.Trigger;

export const TooltipPortal = TooltipPrimitive.Portal;

export const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...rest }, ref) => {
  const { delay } = React.useContext(TooltipGroupContext) ?? {};

  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={twMerge(
        delay != null &&
          delay === 0 &&
          "data-[side=bottom]:slide-in-from-top-0 data-[side=left]:slide-in-from-right-0 data-[side=right]:slide-in-from-left-0 data-[side=top]:slide-in-from-bottom-0",
        "bg-ds-bg-strong text-ds-text-inverse animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-md px-2 py-1 text-xs font-normal",
        className,
      )}
      {...rest}
    />
  );
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
