import { ReactComponent as Logo } from "shared/components/svg/logo.svg";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";

const Main = styled.div`
  position: relative;

  ${(props) =>
    props.hasConfetti &&
    `
    background-image: url('/images/setup-confetti.png');
    background-size: auto 820px;
    background-repeat: no-repeat;
    background-position: center;

    @media only screen and (-o-min-device-pixel-ratio: 5/4),
      only screen and (-webkit-min-device-pixel-ratio: 1.25),
      only screen and (min--moz-device-pixel-ratio: 1.25),
      only screen and (min-device-pixel-ratio: 1.25),
      only screen and (min-resolution: 1.25dppx) {
        background-image: url('/images/setup-confetti@2x.png');
    }
  `}

  ${screen.above(
    bp.mobile,
    `
    display: flex;
    min-height: 100vh;
  `,
  )}
`;

const Content = styled.div`
  width: 100%;
  max-width: 520px;
  padding: 40px;
  margin: auto;
  background-color: var(--white);
  border-radius: 15px;
  text-align: center;

  ${screen.below(
    bp.mobile,
    `
    padding: 24px;
    margin: 72px auto auto;
  `,
  )}
`;

const StyledLogo = styled(Logo)`
  transform: scale(1);
  margin-bottom: 30px;
  width: 200px;
`;

const Maintenance = () => {
  return (
    <Main>
      <Content>
        <StyledLogo />
        <div style={{ height: 300 }}>
          <iframe
            title="giphy"
            src="https://giphy.com/embed/bmrxNoGqGNMAM"
            width="100%"
            height="100%"
            frameBorder="0"
            class="giphy-embed"
            allowFullScreen
          ></iframe>
        </div>
        <p style={{ margin: 0 }}>
          <a href="https://giphy.com/gifs/Sk3KytuxDQJQ4">via GIPHY</a>
        </p>
        <p>
          We are currently working on something awesome.
          <br />
          Please check back later!
        </p>
      </Content>
    </Main>
  );
};

export default Maintenance;
