import { ErrorMessage } from "@hookform/error-message";
import { at } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import MaskedInput from "react-text-mask";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";
import HelperText from "./HelperText";
import Label from "./Label";

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  font-size: 1rem;
  grid-column: span ${(props) => props.$span || 1};

  ${screen.below(
    bp.mobile,
    `
    grid-column: span 2;
  `,
  )}
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 0 12px;
  height: 48px;
  font: inherit;
  line-height: 1;
  color: inherit;
  border-radius: var(--input-border-radius, var(--border-radius));
  border: 1px solid var(--border-gray);
  background-color: var(--off-white);
  appearance: none;

  ${(props) =>
    props.$small &&
    `
    padding: 7px 8px;
    height: 36px;
  `}

  ${(props) =>
    props.errors &&
    props.errors[props.name] &&
    `
    border-color: var(--error-red);
  `};

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  &:-webkit-autofill,
  &:-internal-autofill-selected {
    -webkit-text-fill-color: var(--black) !important;
  }

  &:invalid,
  &:required {
    box-shadow: none;
  }

  &::placeholder {
    opacity: 1;
    color: var(--placeholder-gray);
  }

  &:focus {
    background-color: var(--white);
    border-color: var(--primary-green);
    box-shadow: var(--focus-shadow);
  }
`;

const Input = React.forwardRef(
  (
    {
      type,
      name,
      value,
      label,
      errors = {},
      hideLabel,
      required,
      placeholder,
      infoText,
      disabled,
      className,
      mask,
      maskPipe,
      maskKeepCharPositions = true,
      small,
      span,
      ...rest
    },
    ref,
  ) => {
    const id = `${type}-${name}`;
    const commonProps = {
      disabled,
      type,
      name,
      errors,
      value,
      id,
      placeholder,
      required,
      ...rest,
    };
    const hasError = at(errors, [name])[0];

    return (
      <InputWrap className={className} $span={span}>
        {label && (
          <Label htmlFor={id} hideLabel={hideLabel}>
            {label}
            {required ? "*" : null}
          </Label>
        )}

        {!mask ? (
          <StyledInput {...commonProps} $small={small} ref={ref} />
        ) : (
          <MaskedInput
            {...commonProps}
            mask={mask}
            keepCharPositions={maskKeepCharPositions}
            pipe={maskPipe}
            render={(ref, props) => <StyledInput {...props} ref={ref} $small={small} />}
          />
        )}

        {(infoText || hasError) && (
          <HelperText>
            {!!infoText && !hasError && <p>{infoText}</p>}
            {hasError && (
              <HelperText.Error>
                <ErrorMessage errors={errors} name={name} />
              </HelperText.Error>
            )}
          </HelperText>
        )}
      </InputWrap>
    );
  },
);

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.any,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  infoText: PropTypes.string,
  className: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.bool, PropTypes.func, PropTypes.array]),
  maskKeepCharPositions: PropTypes.bool,
  small: PropTypes.bool,
  span: PropTypes.number,
};

export default Input;
