import create from "zustand";

type ElementStoreAction =
  | {
      name: "commandKSearchInput";
      element: HTMLInputElement | null;
    }
  | {
      name: "layoutSheetRef";
      element: HTMLDivElement | null;
    };

type ElementStore = {
  elements: {
    commandKSearchInput: HTMLInputElement | null;
    layoutSheetRef: HTMLDivElement | null;
  };
  setElement: (action: ElementStoreAction) => void;
};
export const useElementStore = create<ElementStore>((set) => ({
  elements: {
    commandKSearchInput: null,
    layoutSheetRef: null,
  },
  setElement: ({ name, element }) => {
    return set((state) => ({
      elements: { ...state.elements, [name]: element },
    }));
  },
}));
