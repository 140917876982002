import api from "shared/api/api";
import { Params } from "shared/api/types/params";
import { createInfiniteQuery } from "shared/util/createInfiniteQuery";

const FILTERS_KEYS = {
  all: ["filters"] as const,
  type: (type: string) => [...FILTERS_KEYS.all, type] as const,
  typeList: (type: string, params: Params) => [...FILTERS_KEYS.type(type), params] as const,
};

export function createUseInfiniteFilter(type: string) {
  return function useInfiniteFilter(params: { [key: string]: unknown }) {
    return createInfiniteQuery({
      fetchFn: async (params, { signal }) => {
        const response = await api.get(`/${type}`, {
          ...params,
          signal,
        });

        return response.data;
      },
      queryKeyFn: (params) => FILTERS_KEYS.typeList(type, params ?? {}),
    })({
      params,
    });
  };
}
