import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";
import { focusRingStyles } from "shared/styles/focus";
import { twMerge } from "tailwind-merge";

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  indeterminate?: boolean;
}
export const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, indeterminate = false, ...rest }, ref) => {
    return (
      <CheckboxPrimitive.Root
        ref={ref}
        className={focusRingStyles({
          className: twMerge(
            "border-ds-stroke-tertiary text-ds-text-inverse hover:border-ds-stroke-secondary hover:bg-ds-bg-weaker data-[state=checked]:bg-ds-primary-base data-[state=checked]:hover:bg-ds-primary-dark data-[state=checked]:focus-visible:bg-ds-primary-dark peer relative grid h-4 w-4 shrink-0 place-items-center overflow-clip rounded border shadow-sm ring-offset-2 transition-colors disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-transparent",
            className,
          ),
        })}
        {...rest}
      >
        <CheckboxPrimitive.Indicator className="inset-center absolute flex items-center justify-center text-current">
          {!indeterminate ? (
            <IconCheckboxCheck className="h-4 w-4" />
          ) : (
            <IconCheckboxIndeterminate className="h-4 w-4" />
          )}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    );
  },
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

// TODO: Move to shared `Icon` component once #603 is merged
function IconCheckboxCheck(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M8.892 14.444 4.178 9.73 5.749 8.16l3.143 3.143 6.285-6.286 1.572 1.572-7.857 7.856Z"
        fill="currentColor"
      />
      <path
        d="m4.178 9.73 4.714 4.714 7.857-7.856-1.572-1.572-6.285 6.286-3.143-3.143L4.178 9.73Z"
        fill="currentColor"
      />
    </svg>
  );
}

// TODO: Move to shared `Icon` component once #603 is merged
function IconCheckboxIndeterminate(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path d="M4.444 8.889h11.112v2.222H4.444V8.89Z" fill="currentColor" />
    </svg>
  );
}
