import { zodResolver } from "@hookform/resolvers/zod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { RankSelectFormField } from "participant/components/RankFormField";
import { SelectPayTypeFormField } from "participant/components/SelectPayTypeFormField";
import { useUpdateSavedRole } from "participant/hooks/useUpdateSavedRole";
import * as React from "react";
import { useForm } from "react-hook-form";
import { PARTICIPANT_QUERIES } from "shared/api/participants";
import { USER_OPTIONS } from "shared/api/user/user";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "shared/components/ds/Form";
import { Input } from "shared/components/ds/Input";
import { Switch } from "shared/components/ds/Switch";
import { Textarea } from "shared/components/ds/Textarea";
import { z } from "zod";

export const EDIT_SAVED_EXTERNAL_ROLE_FORM_ID = "edit-saved-external-role" as const;
export function EditSavedExternalRoleForm({
  savedRoleId,
  children,
  onEditSuccess,
  onEditFailure,
}: {
  savedRoleId: number;
  children?: ((isSubmitting: boolean) => React.ReactNode) | React.ReactNode;
  onEditSuccess?: (data: EditSavedExternalRoleFormOutput) => void;
  onEditFailure?: () => void;
}) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const { data: savedRole } = useSuspenseQuery(
    PARTICIPANT_QUERIES.savedRoleDetail<"external">(user.id.toString(), savedRoleId),
  );
  const updateSavedRoleMutation = useUpdateSavedRole(savedRoleId);
  const form = useForm<EditSavedExternalRoleFormInput>({
    mode: "onSubmit",
    resolver: zodResolver(EditSavedExternalRoleFormSchema),
    shouldUnregister: true,
    defaultValues: {
      isRemote: savedRole.isRemote ?? false,
      company: typeof savedRole.company === "string" ? savedRole.company : savedRole.company.name,
      job: savedRole.job,
      compensation: savedRole.compensation ?? 0,
      payType: savedRole.payType,
      rank: savedRole.rank ?? "None",
      notes: savedRole.notes ?? "",
      url: savedRole.url ?? "",
    },
  });
  const { handleSubmit, control } = form;

  return (
    <>
      <Form {...form}>
        <form
          id={EDIT_SAVED_EXTERNAL_ROLE_FORM_ID}
          onSubmit={handleSubmit((data) => {
            const editedExternalRole = data as unknown as EditSavedExternalRoleFormOutput;
            updateSavedRoleMutation.mutate(editedExternalRole, {
              onSuccess: () => {
                onEditSuccess?.(editedExternalRole);
              },
              onError: () => {
                onEditFailure?.();
              },
            });
          })}
        >
          <div className="flex flex-col gap-6">
            <div className="flex w-full flex-wrap gap-x-6 gap-y-6">
              <FormField
                control={control}
                name="company"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel>Company</FormLabel>
                      <FormControl>
                        <Input type="text" placeholder="Acme Inc." {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="job"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel>Job Title</FormLabel>
                      <FormControl>
                        <Input type="text" placeholder="Software Engineer" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </div>
            <div className="flex w-full flex-wrap gap-x-6 gap-y-6">
              <FormField
                control={control}
                name="rank"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel optional>Rank</FormLabel>
                      <RankSelectFormField {...field} />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={control}
                name="compensation"
                render={({ field }) => {
                  return (
                    <FormItem className="w-full min-w-[280px] flex-1">
                      <FormLabel optional>Compensation</FormLabel>
                      <div className="flex items-center gap-3">
                        <div className="w-full flex-1">
                          <FormControl>
                            <Input
                              {...field}
                              type="number"
                              prefix={<span>$</span>}
                              prefixStyles
                              step=".01"
                              placeholder="0"
                            />
                          </FormControl>
                        </div>
                        {field.value != null && (
                          <div className="w-auto flex-shrink-0">
                            <FormField
                              control={control}
                              name="payType"
                              render={({ field }) => {
                                return <SelectPayTypeFormField {...field} />;
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </FormItem>
                  );
                }}
              />
            </div>
            <FormField
              control={control}
              name="notes"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel optional>Notes</FormLabel>
                    <div
                      className="grow-wrap after:px-3 after:py-2 after:content-[attr(data-content)_'_']"
                      data-content={field.value}
                    >
                      <FormControl>
                        <Textarea className="" {...field} placeholder="Add notes..." />
                      </FormControl>
                    </div>
                  </FormItem>
                );
              }}
            />

            <FormField
              control={control}
              name="url"
              render={({ field }) => {
                return (
                  <FormItem className="w-full min-w-[280px] flex-1">
                    <FormLabel>Job Posting URL</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="https://www.example.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <div className="self-start">
              <FormField
                control={control}
                name="isRemote"
                render={({ field: { value, onChange } }) => {
                  return (
                    <FormItem className="flex-row gap-2">
                      <FormControl>
                        <Switch checked={value} onCheckedChange={onChange} />
                      </FormControl>
                      <FormLabel>Is this role remote?</FormLabel>
                    </FormItem>
                  );
                }}
              />
            </div>
          </div>
        </form>
      </Form>
      {typeof children === "function" ? children(updateSavedRoleMutation.isPending) : children}
    </>
  );
}

const EditSavedExternalRoleFormSchema = z.object({
  isRemote: z.boolean(),
  url: z.string().url().or(z.literal("")).optional(),
  company: z
    .string({
      errorMap: () => ({
        message: "Please enter a company name",
      }),
    })
    .min(1, {
      message: "Please enter a company name",
    }),
  job: z
    .string({
      errorMap: () => ({
        message: "Please enter a job title",
      }),
    })
    .min(1, {
      message: "Please enter a job title",
    }),
  compensation: z.coerce
    .number({
      errorMap: () => ({
        message: "Please enter a compensation amount",
      }),
    })
    .optional(),
  payType: z
    .enum(["Salary", "Hourly"], {
      errorMap: () => ({
        message: "Please select a pay type",
      }),
    })
    .optional(),
  notes: z.string().optional(),
  rank: z
    .enum(["None", "Low", "Medium", "High"], {
      errorMap: () => ({
        message: "Select a rank",
      }),
    })
    .transform((value) => {
      if (value === "None") return null;
      return value;
    })
    .optional(),
});

type EditSavedExternalRoleFormInput = z.input<typeof EditSavedExternalRoleFormSchema>;

type EditSavedExternalRoleFormOutput = z.output<typeof EditSavedExternalRoleFormSchema>;
