import { size } from "polished";
import PropTypes from "prop-types";
import { ReactComponent as SystemHeadshot } from "shared/components/svg/system-headshot.svg";
import styled from "styled-components";
import RatioImg from "./RatioImg";

/**
 * Get initials from first/last name of user
 *
 * @param {string[]} text
 */
const getInitials = (text) => {
  if (!text) return;

  return text
    .map((string) => typeof string === "string" && string.charAt(0))
    .join("")
    .toUpperCase();
};

const CircleImg = styled(RatioImg)`
  border-radius: 50%;
  overflow: hidden;
`;

const FallbackCircle = styled.div`
  ${size("var(--avatar-override-size, var(--size))")}
  background-color: var(--fallback-bg-color, var(--light-green));
  color: var(--fallback-text-color, var(--black));
  border-radius: 50%;
  line-height: var(--size);
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.075em;
  font-size: var(--font-size);
`;

export const SystemAvatar = styled(SystemHeadshot)`
  ${size("var(--avatar-override-size, var(--size))")}
`;

const Avatar = ({ size, src, fallback, fallbackFontSize, style, ...props }) => {
  if (!src && fallback && fallback[0]) {
    return (
      <FallbackCircle
        style={{
          "--size": `${size}px`,
          "--font-size": `${fallbackFontSize || 16}px`,
          ...style,
        }}
        {...props}
      >
        {getInitials(fallback)}
      </FallbackCircle>
    );
  } else if (src) {
    return <CircleImg width={size} height={size} src={src} style={style} {...props} />;
  } else {
    return <SystemAvatar style={{ "--size": `${size}px`, ...style }} {...props} />;
  }
};

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
  src: PropTypes.string,
  fallback: PropTypes.array,
  fallbackFontSize: PropTypes.number,
  style: PropTypes.object,
};

export default Avatar;
