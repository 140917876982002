import { Helmet } from "react-helmet";
import { Outlet, useParams } from "react-router-dom";
import { prefetchJobDetail, useSuspenseJobDetail } from "shared/api/jobs";
import Button from "shared/components/Button";
import CreateTaskModal from "shared/components/CreateTaskModal";
import { useModal } from "shared/components/Modal";
import ResourceInfoBlock from "shared/components/ResourceInfoBlock";
import { TabNavigation, TabNavigationItem } from "shared/components/TabNavigation";
import { ButtonLink } from "shared/components/ds/Button";
import { GAME_PLAN_SEARCH_PARAM } from "team/components/ParticipantSearch/constants/participant-search.constants";
import { JOB_PATHS, PARTICIPANT_PATHS } from "team/constants/paths.constants";

export async function jobLoader({ params }, queryClient) {
  try {
    return await prefetchJobDetail(params?.jobId, queryClient);
  } catch (_error) {
    return null;
  }
}

export function JobView() {
  const { jobId } = useParams();
  const { modalOpen, modalData, toggleModal } = useModal();
  const { data: job } = useSuspenseJobDetail(jobId);

  return (
    <div className="content-container">
      <Helmet title={job.title} />

      <div className="flex flex-wrap justify-between gap-3 pb-12">
        <ResourceInfoBlock
          job={job}
          partner={job.partner}
          primaryTitleSize="large"
          primaryURL={job.url}
          showHighPriority
        />

        <div className="stack-x-4">
          <ButtonLink
            to={{
              pathname: PARTICIPANT_PATHS.root,
              search: `${GAME_PLAN_SEARCH_PARAM}=${jobId}`,
            }}
            className="relative overflow-visible"
          >
            Game Plan{" "}
            <span
              aria-hidden
              className="bg-ds-secondary-lighter text-ds-secondary-darker absolute right-0 top-0 inline-block -translate-y-1/2 translate-x-2 rounded px-1 py-0.5 text-center text-xs shadow-sm"
            >
              Beta
            </span>
          </ButtonLink>
          <Button
            $small
            onClick={() => {
              toggleModal("createTask", {
                jobId: job.id,
                partnerId: job.partner?.id,
              });
            }}
          >
            + Task
          </Button>
        </div>
      </div>

      <TabNavigation>
        <TabNavigationItem to={JOB_PATHS.detail(jobId)} end>
          Game Plan
        </TabNavigationItem>
        <TabNavigationItem to={JOB_PATHS.interviews(jobId)} end>
          Interviews
        </TabNavigationItem>
        <TabNavigationItem to={JOB_PATHS.edit(jobId)} end>
          Role Info
        </TabNavigationItem>
        <TabNavigationItem to={JOB_PATHS.insight(jobId)} end>
          Insight
        </TabNavigationItem>
        <TabNavigationItem to={JOB_PATHS.editStatuses(jobId)} end>
          Status
        </TabNavigationItem>
      </TabNavigation>

      <div className="pt-8">
        <Outlet />
      </div>

      <CreateTaskModal modalOpen={"createTask" === modalOpen} modalData={modalData} onRequestClose={toggleModal} />
    </div>
  );
}
