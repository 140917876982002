/**
 * Attempts to import a component lazily and retries once by refreshing the page upon failure. This is used to avoid
 * webpack chunk errors when lazy loading modules.
 *
 * @param {() => Promise<T>} componentImport - Function that returns a promise resolving to the component to be
 *   imported.
 * @param {string} name - Unique name used as a key to store the refresh state in sessionStorage.
 * @returns {Promise<T>} - A promise that resolves with the imported component, or rejects with an error.
 */
export function lazyRetry<T>(componentImport: () => Promise<T>, name: string): Promise<T> {
  return new Promise((resolve, reject) => {
    // Check if the page has already been refreshed for this component
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false");
    componentImport()
      .then((component) => {
        // On successful import, reset the refresh state
        window.sessionStorage.setItem(`retry-${name}-lazy-refreshed`, "false");
        resolve(component);
      })
      .catch((error) => {
        // If there was an error and the page hasn't been refreshed yet
        if (!hasRefreshed) {
          // Set the refresh state and reload the page
          window.sessionStorage.setItem(`retry-${name}-lazy-refreshed`, "true");
          return window.location.reload();
        }

        // If the page was already refreshed, reject the promise with the error
        reject(error);
      });
  });
}
