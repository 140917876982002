export const UTAH_PATHS = {
  onboarding: "onboarding/utah" as const,
} as const;

export const UTAH_PROGRAM_ID = 10001;

export const UTAH_PROGRAM_AVAILABLE_STEPS = {
  welcome: {
    id: "welcome",
    slug: createUtahSlug(""),
    step: 0,
  },
  "basic-info": {
    id: "basic-info",
    slug: createUtahSlug("/basic-info"),
    step: 1,
  },
  demographics: {
    id: "demographics",
    slug: createUtahSlug("/self-identification"),
    step: 2,
  },
  taxonomy: {
    id: "taxonomy",
    slug: createUtahSlug("/taxonomy"),
    step: 3,
  },
  bonus: {
    id: "profile-enrichment",
    slug: createUtahSlug("/bonus"),
    step: 4,
  },
  commitment: {
    id: "commitment",
    slug: createUtahSlug("/commitment"),
    step: 5,
  },
} as const;
export type StepId = keyof typeof UTAH_PROGRAM_AVAILABLE_STEPS;
export type StepNum = (typeof UTAH_PROGRAM_AVAILABLE_STEPS)[StepId]["step"];

function createUtahSlug<TSlug extends string>(slug: TSlug) {
  return `/${UTAH_PATHS.onboarding}${slug}` as const;
}
