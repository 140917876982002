import { parseFiltersFromUrl } from "shared/components/FiltersV2/helpers/parseFiltersFromUrl";
import { mapFilterGroupStateToRecord } from "shared/components/FiltersV2/helpers/transformFilters";

export function getFilterParams({
  searchParams,
  key,
  initialFilters = {},
}: {
  searchParams: URLSearchParams;
  key: string;
  initialFilters?: Record<string, { id: string; name: string }[]>;
}) {
  const hasKey = searchParams.has(key);
  const parsedFilters = hasKey ? parseFiltersFromUrl(searchParams, key) : {};
  const filters = {
    ...(hasKey ? mapFilterGroupStateToRecord(parsedFilters) : mapFilterGroupStateToRecord(initialFilters)),
  };

  return filters;
}
