/* eslint-disable jsx-a11y/iframe-has-title */
import PropTypes from "prop-types";
import { parseVideo } from "shared/util/getYouTubeID";

const VideoEmbed = ({ url }) => {
  if (!url || typeof url !== "string") return null;

  const videoInfo = parseVideo(url);

  if (!videoInfo?.id || !videoInfo?.type) return null;

  if (videoInfo.type === "youtube") {
    return (
      <iframe width="160" height="90" src={`https://www.youtube.com/embed/${videoInfo.id}?rel=0`} frameBorder="0" />
    );
  }

  if (videoInfo.type === "vimeo") {
    return <iframe width="160" height="90" src={`https://player.vimeo.com/video/${videoInfo.id}`} frameborder="0" />;
  }

  return null;
};

VideoEmbed.propTypes = {
  url: PropTypes.string,
};

export default VideoEmbed;
