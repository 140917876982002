import { tv } from "tailwind-variants";

export const heading = tv(
  {
    base: "text-ds-text-primary font-semibold tracking-tight m-0",
    variants: {
      variant: {
        "72": "text-7xl tracking-tighter",
        "60": "text-6xl",
        "48": "text-5xl",
        "36": "text-4xl",
        "30": "text-3xl",
        "24": "text-2xl",
        "20": "text-xl",
        "16": "text-base",
      },
    },
    defaultVariants: {
      variant: "36",
    },
  },
  {
    responsiveVariants: true,
  },
);
