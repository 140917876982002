export const DASHBOARD_PATHS = {
  root: "/" as const,
} as const;

export const COMPANIES_PATHS = {
  companies: "/companies" as const,
  detail: (id: string) => {
    return `${COMPANIES_PATHS.companies}/${id}` as const;
  },
} as const;

export const NOTIFICATION_PATHS = {
  root: `/notifications` as const,
} as const;

export const ACCOUNT_PATHS = {
  root: `/account` as const,
  notifications: () => `${ACCOUNT_PATHS.root}/notifications` as const,
  skills: () => `${ACCOUNT_PATHS.root}/skills` as const,
} as const;

export const CONTENT_LIBRARY_PATHS = {
  root: `/content-library` as const,
  detail: (id: string) => `${CONTENT_LIBRARY_PATHS.root}/${id}` as const,
} as const;

export const EVENTS_PATHS = {
  root: `/events` as const,
  detail: (id: string) => `${EVENTS_PATHS.root}/${id}` as const,
  type: (type: string) => `${EVENTS_PATHS.root}/type/${type}` as const,
} as const;

export const INTERIVEW_PATHS = {
  root: "/interviews" as const,
  detail: (id: string) => `${INTERIVEW_PATHS.root}/${id}` as const,
} as const;

export const SURVEYS_PATHS = {
  root: "/surveys" as const,
  reflections: "/interview-reflections" as const,
  detail: (id: string) => `${SURVEYS_PATHS.root}/${id}` as const,
} as const;
