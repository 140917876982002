import { rem } from "polished";
import DataTable from "react-data-table-component";
import { LoadingContainer } from "shared/components/ds/Spinner";
import { formatCommaNumbers } from "shared/util/formatText";
import styled from "styled-components";
import screen from "superior-mq";
import { bp } from "../../styles/helpers";

const customStyles = {
  headCells: {
    color: "#000000",
    activeSortStyle: {
      "&:focus": {
        color: "#0D6565",
      },
      "&:hover": {
        color: "#000000",
      },
    },
    inactiveSortStyle: {
      "&:focus": {
        color: "#0D6565",
      },
      "&:hover": {
        color: "#000000",
      },
    },
  },
};

const StyledTable = styled(DataTable).attrs((props) => ({
  sortServer: true,
  noHeader: props.noHeader === false ? false : true,
  subHeader: false,
  paginationServer: true,
  customStyles: customStyles,
  progressComponent: <LoadingContainer level="component" />,
}))`
  color: var(--black);
  margin-bottom: 20px;
  font-size: ${rem(15)};

  .rdt_TableCol {
    font-size: inherit;
    font-weight: 600;
    padding-right: 12px;
    padding-left: 12px;
  }

  .rdt_TableHead {
    font-size: ${rem(14)};

    ${(props) =>
      !props.showMobileHeader &&
      screen.below(
        bp.portrait,
        `
        display: none;
      `,
      )}
  }

  .rdt_TableHeadRow {
    border-bottom: var(--border);
  }

  .rdt_TableRow {
    padding: ${(props) => (props.small ? "15px 0" : "24px 0")};
    border: 0;
    font-size: inherit;
    border-radius: var(--border-radius);

    &:nth-of-type(even) {
      background-color: var(--off-white);
    }

    ${screen.below(
      bp.portrait,
      `
      display: block;
      margin-bottom: 32px;
      border-bottom: solid 2px #b3c2C8;
      border-top: solid 2px #b3c2C8;
      background: var(--offwhite);
      border-radius: 0;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    `,
    )}

    ${(props) =>
      props.showMobileHeader &&
      screen.below(
        bp.portrait,
        `
        display: flex;
      `,
      )}
  }

  .rdt_TableCell {
    padding-right: 12px;
    padding-left: 12px;
    align-items: ${(props) => (props.cellAlign ? props.cellAlign : "center")};

    ${screen.below(
      bp.portrait,
      `
      justify-content: space-between;
      max-width: unset;
      border-bottom: solid 1px #b3c2C8;
      align-items: center;
    `,
    )}

    ${(props) =>
      props.showMobileHeader &&
      screen.below(
        bp.portrait,
        `
      align-items: flex-start;
      `,
      )}
  }
`;

const ResultsHeader = styled.header`
  padding: 10px 0;
  font-size: ${rem(15)};

  ${screen.below(
    bp.portrait,
    `
      margin-bottom: 10px;
    `,
  )}
`;

const Table = (props) => {
  return (
    <>
      {props.paginationTotalRows ? (
        <ResultsHeader>
          {formatCommaNumbers(props.paginationTotalRows)}
          &nbsp;result{props.paginationTotalRows > 1 ? "s" : null}
        </ResultsHeader>
      ) : null}
      <StyledTable {...props} />
    </>
  );
};

Table.dashTableStyles = {
  fontSize: ".875rem",
  padding: "8px",
  marginBottom: 0,
};
Table.dashPaginationStyles = {
  fontSize: "1rem",
  paddingRight: "8px",
  marginBottom: 0,
};

export default Table;

export { default as DataCell } from "./DataCell";
export { default as ManageCell } from "./ManageCell";
