import Tippy from "@tippyjs/react";
import styled from "styled-components";

import "tippy.js/dist/tippy.css";

const Tooltip = styled(Tippy)`
  && {
    padding: 6px 8px;
    background-color: var(--white);
    color: var(--black);
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.18));

    .tippy-arrow {
      color: var(--white);
    }
  }
`;

export default Tooltip;
