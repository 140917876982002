import { ErrorMessage } from "@hookform/error-message";
import { useCombobox, useMultipleSelection } from "downshift";
import { at } from "lodash";
import { rem, size } from "polished";
import PropTypes from "prop-types";
import * as React from "react";
import { useMemo, useState } from "react";
import Avatar from "shared/components/Avatar";
import Button from "shared/components/Button";
import ButtonGroup from "shared/components/ButtonGroup";
import UnstyledButton from "shared/components/UnstyledButton";
import Table, { DataCell } from "shared/components/table";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";
import apiService from "../../api/api-service";
import Tag from "../Tag";
import DownshiftStyles from "./DownshiftStyles";
import HelperText from "./HelperText";
import Input from "./Input";
import Label from "./Label";

const InputWrap = styled.div`
  display: flex;
  height: 48px;
  background-color: var(--off-white);
  border: var(--border);
  border-radius: var(--border-radius);
  overflow: hidden;

  &[aria-expanded="true"] {
    background-color: var(--white);
    border-color: var(--primary-green);
    box-shadow: var(--focus-shadow);
  }

  svg {
    ${size(8, 15)};
  }

  ${(props) =>
    props.$small &&
    `
    height: 36px;
    line-height: 34px;
    font-size: ${rem(14)};

    svg {
      width: 13px;
      height: 6px;
      vertical-align: super;
    }

    input {
      width: 120px;
    }
  `}

  ${(props) =>
    props.disabled &&
    `
    opacity: .4;
  `}
`;

const SelectWrap = styled.div`
  position: relative;
  grid-column: span ${(props) => props.$span || 1};

  ${screen.below(
    bp.tablet,
    `
    grid-column: span 2;
  `,
  )}
`;

const SelectInput = styled.input`
  flex: 1 1 auto;
  padding: 12px 12px;
  appearance: none;
  border: 0;
  background-color: inherit;
  outline: none;
  font: inherit;
  line-height: 1;
`;

const ToggleButton = styled(UnstyledButton)`
  padding: 12px;
  vertical-align: center;
`;

const PopOver = styled(DownshiftStyles.Popover)``;

const MenuItem = styled(DownshiftStyles.MenuItem)`
  ${(props) =>
    props.highlight &&
    `
    background-color: var(--off-white);
  `}
`;

const SearchMenu = styled(DownshiftStyles.SearchMenu)``;
/*
const SearchMenu = styled.ul`
  list-style: none;
  position: absolute;
  width: 100%;
  max-height: 200px;
  width: 250px;
  margin: 0;
  overflow: auto;
  background: ${(props) => props.theme.white};
  z-index: 1;
`; */

const SelectedWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const PersonItemWrap = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: ${rem(14)};
`;

const SelectedTag = styled(Tag)`
  margin: 7px 7px 0 0;

  ${(props) =>
    props.text === null &&
    `
    line-height: 20px;

    & > button {
      vertical-align: text-bottom;
    }
  `}
`;

const PersonItem = ({ item }) => (
  <PersonItemWrap>
    <Avatar
      src={item.headshot?.location}
      alt=""
      size={32}
      style={{ width: "32px", margin: "7px 7px 7px 0" }}
      fallback={[item.firstName, item.lastName]}
      fallbackFontSize={12}
    />
    <div>
      <div>
        {item.firstName} {item.lastName}
      </div>
      <div>{item.email}</div>
    </div>
  </PersonItemWrap>
);

/**
 * A multiple select controlled form component built with Downshift. Meant to be used with react-hook-form for
 * validation and error display.
 *
 * @param {string} name -The name of the input (for tracking validation errors)
 * @param {string} label - Text to use as the lable for the form field.
 * @param {Array} [options] -The available options. Format should be [{value:1, label:'someplace},...]
 * @param {string} [apiPath] -If this prop is set, the above options are ignored. Instead the given API path is called
 *   to retrieve the options. The user search term is passed as a param (search).
 * @param {string} [apiParams] -param string to add to the API request
 * @param {Function} [optionFormatter] -A function to transform option data to the correct format, [{value:1,
 *   label:'someplace},...]
 * @param {Number} [optionLimit] -Limit the number of options to show at a time.
 * @param {Function} onChange - Function to call when value changes (react-hook-form handler).
 * @param {Function} onBlur - Function to call when element looses focus (react-hook-form handler).
 * @param {Object} value - Value if the field field (react-hook-form managed).
 * @param {Object} errors - Errors to display (react-hook-form managed).
 * @param {bool} hideLabel - If true, the lable is output but not displayed.
 * @param {bool} singleMode - If true, only one item can be selected.
 * @param {bool} showToggleButton - If true, the menu toggle button will be displayed
 * @param {Number} span - Number of grid columns to span.
 * @param {string} selectedLayout - The layout to use when displaying selected items.
 */
const MultiSelect = React.forwardRef(
  (
    {
      name,
      label,
      options,
      apiPath,
      apiParams,
      optionFormatter,
      optionLimit = 20,
      singleMode,
      onChange,
      onRemove,
      onBlur,
      value,
      errors = {},
      hideLabel,
      hideSelected = false,
      showToggleButton,
      span,
      disabled,
      boldLabel = false,
      required = false,
      selectedLayout = "default",
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState("");

    const { data, isLoading, isError } = apiService.useGet(() => {
      // API is only called if path was set.
      if (!apiPath || disabled) return false;
      const path = apiParams ? `${apiPath}?${apiParams}&` : `${apiPath}?`;
      return `${path}limit=${optionLimit}${inputValue && `&term=${inputValue}`}`;
    });

    // If apiPath is passed in we should use returned data as the items.
    // Otherwise use the static options
    const items = useMemo(() => {
      const rawItems = apiPath && !isError && data ? data.items || [] : options || [];
      return optionFormatter ? rawItems.map(optionFormatter) : rawItems;
    }, [data, isError, options, optionFormatter, apiPath]);

    const handleSelectedItemsChange = ({ selectedItems }) => {
      const selectedItemMap = new Map();

      selectedItems.forEach((selectedItem) => {
        selectedItemMap.set(selectedItem.value, selectedItem);
      });

      return void onChange(Array.from(selectedItemMap, ([name, value]) => value));
    };

    const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } =
      useMultipleSelection({
        onSelectedItemsChange: handleSelectedItemsChange,
        selectedItems: value,
      });

    // Get the list of options, remove any that have already been selected.
    const getFilteredItems = (items) => {
      if (!items || items.length < 1) return [];
      return items.filter((item) => {
        const notSelected = selectedItems.findIndex((s) => s.value === item.value) < 0;

        // If we're retrieving items from the API the API is doing filtering
        // for us.
        if (apiPath) {
          return notSelected;
        }

        return notSelected && item.label.toLowerCase().startsWith(inputValue.toLowerCase());
      });
    };

    const onComboStateChange = ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue("");
            if (singleMode) {
              // This is ugly but it seems to be working without issue.
              setTimeout(() => {
                removeSelectedItem(selectedItems[0]);
              }, 10);
            }
            addSelectedItem(selectedItem);
            selectItem(null);
          }
          break;
        default:
          break;
      }
    };

    const onInputChange = ({ inputValue }) => {
      if (inputValue !== "") setHighlightedIndex(0);
    };

    const {
      isOpen,
      getLabelProps,
      getMenuProps,
      getInputProps,
      getToggleButtonProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      setHighlightedIndex,
      selectItem,
    } = useCombobox({
      inputValue,
      items: getFilteredItems(items),
      onStateChange: onComboStateChange,
      onInputValueChange: onInputChange,
    });

    return (
      <SelectWrap $small $span={span} ref={ref}>
        <div>
          {label && (
            <Label {...getLabelProps()} hideLabel={hideLabel} $bold={boldLabel}>
              {label}
              {required ? "*" : null}
            </Label>
          )}
          <InputWrap {...getComboboxProps({ disabled: disabled })}>
            <SelectInput
              {...getInputProps({
                ...getDropdownProps({ preventKeyAction: isOpen }),
                onBlur: onBlur,
                disabled: disabled,
              })}
            />
            {showToggleButton && !disabled && (
              <ToggleButton type="button" {...getToggleButtonProps()} aria-label="toggle menu">
                <svg>
                  <use xlinkHref="#chevron" />
                </svg>
              </ToggleButton>
            )}
          </InputWrap>
        </div>
        <PopOver {...getMenuProps()} isOpen={isOpen}>
          <SearchMenu>
            {isOpen && isLoading && inputValue && apiPath && <div>Loading...</div>}
            {isOpen &&
              (!isLoading || !apiPath) &&
              getFilteredItems(items).map((item, index) => (
                <MenuItem
                  highlight={highlightedIndex === index}
                  key={`${item.label}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {item.email || item.headshot ? <PersonItem item={item} /> : item.label}
                </MenuItem>
              ))}
          </SearchMenu>
        </PopOver>

        <SelectedWrap>
          {selectedLayout === "experienceTable" ? (
            <Table
              noDataComponent=""
              columns={[
                {
                  name: "Years of Relevant Experience",
                  selector: "row.relevantExperience",
                  maxWidth: "200px",
                  cell: (row) => (
                    <DataCell name="Years of Relevant Experience">
                      <Input
                        disabled={disabled}
                        type="number"
                        min={0}
                        step="0.5"
                        value={row.relevantExperience || 0}
                        onChange={(e) => {
                          if (disabled) return;
                          e.stopPropagation();
                          e.preventDefault();
                          removeSelectedItem(row);

                          addSelectedItem({
                            ...row,
                            relevantExperience: e.target.value,
                          });
                        }}
                      />
                    </DataCell>
                  ),
                },
                {
                  name: "Name",
                  selector: "row.name",
                  cell: (row) => <DataCell name="Status">{row.persona?.name || row.label}</DataCell>,
                },
                {
                  name: "Action",
                  selector: "row.action",
                  sort: false,
                  maxWidth: "120px",
                  cell: (row) => (
                    <DataCell name="Action">
                      <ButtonGroup>
                        <Button
                          $small
                          $theme="secondary"
                          onClick={(e) => {
                            if (disabled) return;
                            e.stopPropagation();
                            e.preventDefault();
                            removeSelectedItem(row);

                            if (onRemove) {
                              onRemove(row);
                            }
                          }}
                        >
                          Remove
                        </Button>
                      </ButtonGroup>
                    </DataCell>
                  ),
                },
              ]}
              data={selectedItems}
              pagination={false}
              small
            />
          ) : (
            <>
              {!hideSelected &&
                selectedItems &&
                selectedItems.map((selectedItem, index) => (
                  <SelectedTag
                    key={`selected-item-${index}`}
                    {...getSelectedItemProps({ selectedItem, index })}
                    text={selectedItem.email ? null : selectedItem.label}
                    onClick={(e) => {
                      if (disabled) return;
                      e.stopPropagation();
                      e.preventDefault();
                      removeSelectedItem(selectedItem);
                      if (onRemove) {
                        onRemove(selectedItem);
                      }
                    }}
                    $small
                    remove
                  >
                    {selectedItem.email && <PersonItem item={selectedItem} />}
                  </SelectedTag>
                ))}{" "}
            </>
          )}
        </SelectedWrap>
        {at(errors, [name])[0] && (
          <HelperText>
            <HelperText.Error>
              <ErrorMessage errors={errors} name={name} />
            </HelperText.Error>
          </HelperText>
        )}
      </SelectWrap>
    );
  },
);

MultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  apiPath: PropTypes.string,
  apiParams: PropTypes.string,
  optionFormatter: PropTypes.func,
  optionLimit: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.array,
  errors: PropTypes.object,
  hideLabel: PropTypes.bool,
  singleMode: PropTypes.bool,
  showToggleButton: PropTypes.bool,
  span: PropTypes.number,
};

export default MultiSelect;
