import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Button } from "shared/components/ds/Button";
import {
  LayoutSheet,
  Sheet,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "shared/components/ds/Sheet";
import { AddToBrainstormForm } from "team/components/AddToBrainstormSheet/components/AddToBrainstormForm";
import { FormErrorState, FormLoadingState } from "team/components/FormStates";

export function AddToBrainstormSheet({
  participant,
  trigger = <Button>Add To Game Plan</Button>,
}: {
  participant: {
    id: number;
    name: string;
  };
  trigger?: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>{trigger}</SheetTrigger>

      <LayoutSheet size="lg">
        <SheetHeader>
          <SheetTitle>Add To Game Plan</SheetTitle>
          <SheetDescription>Please fill out the form below to add a participant to your Game Plan.</SheetDescription>
        </SheetHeader>
        <div className="relative z-10 p-6">
          <ErrorBoundary fallbackRender={() => <FormErrorState />}>
            <React.Suspense fallback={<FormLoadingState />}>
              <AddToBrainstormForm
                participant={participant}
                onSuccessfulSubmit={() => {
                  setOpen(false);
                }}
                onCancel={() => {
                  setOpen(false);
                }}
              />
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </LayoutSheet>
    </Sheet>
  );
}
