export function getSkip(page: number, size: number) {
  return (page - 1) * size;
}
export function getPageTotal(total: number, size: number) {
  return Math.ceil(total / size);
}

export function getRowStart(page: number, size: number, total: number) {
  return Math.min((page - 1) * size + 1, total);
}

export function getRowEnd(page: number, size: number, total: number) {
  return Math.min(page * size, total);
}
