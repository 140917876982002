import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import { hover } from "../styles/helpers";
import UnstyledButton from "./UnstyledButton";

const StyledTag = styled.span<{
  grayBackground?: boolean;
  remove?: boolean;
  highlightYellow?: boolean;
  highlightLight?: boolean;
  small?: boolean;
}>`
  position: relative;
  display: inline-block;
  padding-left: 8px;
  line-height: 34px;
  border: var(--border);
  border-radius: 4px;
  font-size: ${rem(15)};
  background-color: ${(props) => (!props.grayBackground ? "var(--white)" : "var(--off-white)")};
  padding-right: ${(props) => (props.remove ? "35px" : "8px;")};

  ${(props) =>
    props.highlightYellow &&
    `
    background-color: var(--burnt-orange);
    `}

  ${(props) =>
    props.highlightLight &&
    `
    background-color: var(--bright-orange);
    `}

  ${(props) =>
    props.small &&
    `
    height: 24px;
    line-height: 22px;
    font-size: ${rem(13)};
  `}
`;

const RemoveButton = styled(UnstyledButton)`
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  right: 0;
  padding: 8px 11px;
  color: var(--primary-green);

  ${hover(`color: var(--error-red);`)}

  svg {
    display: inline-block;
  }
  path {
    transition: color 0.3s;
  }
`;

type TagProps = (
  | {
      remove: false;
      onClick: never;
    }
  | {
      remove: true;
      onClick: () => void;
    }
) & {
  text?: string;
  grayBackground?: boolean;
  small?: boolean;
  children?: React.ReactNode;
};

const Tag = React.forwardRef<HTMLButtonElement, TagProps>(
  ({ remove = false, onClick, text, grayBackground, small, children, ...props }, ref) => (
    <StyledTag remove={remove} grayBackground={grayBackground} small={small} {...props}>
      {children ? children : text}
      {remove ? (
        <RemoveButton aria-label={`Remove ${text}`} onClick={onClick} type="button" ref={ref}>
          <svg width="12" height="12">
            <use xlinkHref="#x-icon" />
          </svg>
        </RemoveButton>
      ) : null}
    </StyledTag>
  ),
);

export const TagInnerContainer = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 0;
`;

export const TagContextualInfo = styled.span`
  display: inline-block;
  padding: 4px 6px;
  border-radius: var(--border-radius);
  background-color: var(--dark-text);
  color: var(--white);
  font-size: 12px;
  line-height: 1;
`;

export default Tag;
