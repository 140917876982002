import styled from "styled-components";

const TableName = styled.span`
  --link-hover-color: var(--primary-green);
  font-weight: 500;
  text-decoration: none;
  color: inherit;
  line-height: 20px;
`;

TableName.Secondary = styled.span`
  line-height: 20px;
  font-size: 13px;
`;

export default TableName;
