import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import {
  ACCOUNT_PATHS as P_ACCOUNT_PATHS,
  DASHBOARD_PATHS as P_DASHBOARD_PATHS,
  NOTIFICATION_PATHS as P_NOTIFICATION_PATHS,
} from "participant/constants/paths.constants";
import * as React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD_QUERY_OPTIONS } from "shared/api/dashboard";
import { USER_OPTIONS, useLogout, useUserProfile } from "shared/api/user/user";
import { getRouteTreeRoot, isBreakLineUser } from "shared/api/user/user.helpers";
import { CurrentUserAvatar } from "shared/components/CurrentUserAvatar";
import { CommandMenu, useCommandMenu } from "shared/components/PrivateAppLayout/components/SiteHeader/CommandMenu";
import { MobileNav } from "shared/components/PrivateAppLayout/components/SiteHeader/MobileNav";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shared/components/ds/DropdownMenu";
import { Kbd } from "shared/components/ds/Kbd";
import { Logomark } from "shared/components/ds/Logomark";
import { toast } from "shared/components/ds/Toast/Toast";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "shared/components/ds/Tooltip";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import useMediaQuery from "shared/hooks/useMediaQuery";
import { focusRingStyles } from "shared/styles/focus";
import { ACCOUNT_PATHS, DASHBOARD_PATHS, NOTIFICATION_PATHS } from "team/constants/paths.constants";

export function SiteHeader() {
  const { data: user } = useUserProfile();
  const [, setSiteHeaderPortal] = React.useContext(SiteHeaderPortalContext) ?? [];

  return (
    <div className="border-ds-stroke-tertiary bg-ds-bg-foundation pointer-events-auto flex h-full items-center justify-between gap-2 border-b border-solid px-5 md:px-6">
      <div className="grid h-10 w-10 place-items-center md:hidden">
        <ErrorBoundary fallback={<Logomark aria-hidden className="w-[30px]" />}>
          <React.Suspense fallback={<Logomark aria-hidden className="w-[30px]" />}>
            <LogoLink>
              <Logomark aria-hidden className="w-[30px]" />
            </LogoLink>
          </React.Suspense>
        </ErrorBoundary>
      </div>

      <div ref={setSiteHeaderPortal} />
      <div className="stack-x-2 ml-auto">
        <div className="divide-ds-stroke-tertiary stack-x-[5px] md:stack-x-3 flex items-center md:divide-x">
          <div className="md:stack-x-3 flex">
            {user && isBreakLineUser(user.type) && <CommandMenu />}

            <NotficationsWidget />
          </div>
          <UserProfileWidget />
        </div>
        <MobileNav />
      </div>
    </div>
  );
}

function LogoLink({ children }: { children?: React.ReactNode }) {
  const { data: user } = useSuspenseQuery(USER_OPTIONS.user());
  const routeTreeRoot = getRouteTreeRoot(user.type);

  return (
    <Link to={routeTreeRoot} aria-label="Go to homepage" className="block w-auto">
      {children}
    </Link>
  );
}

const SiteHeaderPortalContext = React.createContext<
  [HTMLDivElement | null, React.Dispatch<React.SetStateAction<HTMLDivElement | null>>] | null
>(null);

interface SiteHeaderPortalProviderProps {
  children: React.ReactNode;
}
export function SiteHeaderPortalProvider({ children }: SiteHeaderPortalProviderProps) {
  const siteHeaderPortalState = React.useState<HTMLDivElement | null>(null);

  return <SiteHeaderPortalContext.Provider value={siteHeaderPortalState}>{children}</SiteHeaderPortalContext.Provider>;
}

interface SiteHeaderProps {
  children: React.ReactNode;
}
export function SiteHeaderPortal({ children }: SiteHeaderProps) {
  const [portalContentElement] = React.useContext(SiteHeaderPortalContext) ?? [];

  return portalContentElement ? ReactDOM.createPortal(children, portalContentElement) : null;
}

function NotficationsWidget() {
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery(DASHBOARD_QUERY_OPTIONS.unreadCount());
  const { data: user } = useUserProfile();
  const hasUnread = data != null && data.total > 0;

  return (
    <TooltipProvider>
      <Tooltip open={open} onOpenChange={setOpen}>
        <TooltipTrigger asChild>
          <Link
            to={user?.type === "breakline" ? NOTIFICATION_PATHS.root : P_NOTIFICATION_PATHS.root}
            aria-label="View Notifications"
            className={focusRingStyles({
              className:
                "hover:border-ds-stroke-tertiary hover:bg-ds-bg-weaker group relative grid h-10 w-10 place-items-center rounded border border-transparent transition-all",
            })}
          >
            <Icon className="text-ds-icon-primary col-span-full row-span-full h-4 w-4 md:h-5 md:w-5" aria-hidden>
              <IconUse id="notification-2-line" />
            </Icon>

            {/** Unread indicator */}
            {hasUnread && (
              <span className="pointer-events-none relative col-span-full row-span-full block h-4 w-4 select-none md:h-5 md:w-5">
                <span
                  aria-hidden
                  className="border-ds-bg-foundation bg-ds-secondary-base group-hover:border-ds-bg-weaker pointer-events-none absolute -right-0.5 -top-0.5 block h-3 w-3 rounded-full border-2 border-solid transition-colors"
                />
              </span>
            )}
          </Link>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          align="end"
          sideOffset={10}
          className="border-ds-stroke-tertiary bg-ds-bg-foundation text-ds-text-primary w-[268px] border p-0 shadow-sm"
        >
          <div className="border-ds-stroke-secondary flex items-center justify-between border-b px-3 py-3">
            <span className="text-sm font-medium">Notificiations</span>
          </div>
          <div className="bg-ds-bg-weaker flex gap-1.5 px-1.5 py-2">
            <NotificationCollectionItem label="FYI" count={data?.notifications ?? 0}>
              <NotificationCollectionItemLink
                label="View All FYIs"
                href={user?.type === "breakline" ? NOTIFICATION_PATHS.root : P_NOTIFICATION_PATHS.root}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </NotificationCollectionItem>
            {isBreakLineUser(user?.type ?? "") && (
              <NotificationCollectionItem label="Tasks" count={data?.tasks ?? 0}>
                <NotificationCollectionItemLink
                  label="View All Tasks"
                  href={NOTIFICATION_PATHS.tasks()}
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </NotificationCollectionItem>
            )}
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

interface NotificationCollectionItemProps {
  label: string;
  count: number;
  children?: React.ReactNode;
}
function NotificationCollectionItem({ label, count, children }: NotificationCollectionItemProps) {
  const countText = count > 99 ? "99+" : count;

  return (
    <div className="linkbox border-ds-stroke-tertiary bg-ds-bg-foundation focus-within:bg-ds-bg-foundation/50 hover:bg-ds-bg-foundation/50 group relative flex flex-1 justify-between rounded border p-3 transition-colors">
      <div className="stack-y-1.5">
        <p className="text-ds-text-secondary m-0 text-sm font-normal">{label}</p>
        <p className="m-0 text-2xl">
          <span className="sr-only">{count}</span>
          <span aria-hidden>{countText}</span>
        </p>
      </div>
      {children}
    </div>
  );
}

interface NotificationCollectionItemLinkProps {
  label: string;
  href: string;
  onClick?: () => void;
}
function NotificationCollectionItemLink({ label, href, onClick }: NotificationCollectionItemLinkProps) {
  return (
    <Link
      to={href}
      onClick={() => {
        onClick?.();
      }}
      className="linkOverlay flex h-min"
    >
      <span className="bg-ds-primary-lighter absolute right-3 grid h-6 w-6 translate-y-1 place-items-center rounded-full opacity-0 transition group-focus-within:translate-y-0 group-focus-within:opacity-100 group-hover:translate-y-0 group-hover:opacity-100">
        <Icon className="text-ds-primary-dark h-3 w-3" aria-hidden>
          <IconUse id="ri-link" />
        </Icon>
      </span>
      <span className="sr-only">{label}</span>
    </Link>
  );
}

function UserProfileWidget() {
  const { data: user } = useUserProfile();
  const isTablet = useMediaQuery("(min-width: 768px)");
  const logoutMutation = useLogout();
  const navigate = useNavigate();
  const fullName = `${user?.firstName ?? ""} ${user?.lastName ?? ""}`;

  return (
    <DropdownMenu>
      <div className="md:pl-3">
        {isTablet ? (
          <>
            {/** Desktop Trigger */}
            <DropdownMenuTrigger
              aria-label={fullName}
              className={focusRingStyles({
                className:
                  "stack-x-3 hover:border-ds-stroke-tertiary hover:bg-ds-bg-weaker items-center overflow-clip rounded-md border border-transparent px-2 py-1 transition-all",
              })}
            >
              <span className="stack-x-2 items-center">
                <CurrentUserAvatar />
                <span className="text-ds-text-primary text-xs">{user?.firstName}</span>
              </span>
              <span className="block" aria-hidden>
                <Icon className="text-ds-icon-primary h-4 w-4" aria-hidden>
                  <IconUse id="arrow-down-s-line" />
                </Icon>
              </span>
            </DropdownMenuTrigger>
          </>
        ) : (
          <>
            {/** Mobile Trigger */}
            <DropdownMenuTrigger
              aria-label={fullName}
              className={focusRingStyles({
                className:
                  "hover:border-ds-stroke-tertiary grid h-10 w-10 place-items-center rounded border border-transparent transition-all",
              })}
            >
              <CurrentUserAvatar size="sm" />
            </DropdownMenuTrigger>
          </>
        )}

        <DropdownMenuContent align="end" sideOffset={10} className="w-[250px]">
          <header className="stack-x-3 items-start px-4 pb-[10px] pt-4">
            <CurrentUserAvatar />

            <div className="stack-y-0 min-w-0">
              <p className="text-ds-text-primary m-0 w-full truncate text-sm">
                {user?.firstName} {user?.lastName}
              </p>
              <p className="text-ds-text-tertiary m-0 w-full truncate text-sm">{user?.email}</p>
            </div>
          </header>
          <DropdownMenuSeparator />
          <DropdownMenuGroup className="relative">
            {user && isBreakLineUser(user?.type) ? <TeamProfileMenuItems /> : <ParticipantProfileMenuItems />}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuGroup className="relative">
            <DropdownMenuItem className="stack-x-2 w-full text-left" asChild>
              <button
                onClick={() => {
                  logoutMutation.mutate(undefined, {
                    onSuccess: () => {
                      navigate("/login", { replace: true });
                    },
                    onError: () => {
                      toast.error("Logout was unsuccessful");
                    },
                  });
                }}
              >
                <Icon aria-hidden className="text-ds-icon-primary h-4 w-4">
                  <IconUse id="logout-box-line" />
                </Icon>
                Logout
              </button>
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </div>
    </DropdownMenu>
  );
}

function TeamProfileMenuItems() {
  const [, setOpen] = useCommandMenu();

  return (
    <>
      <DropdownMenuItem asChild>
        <Link
          to={DASHBOARD_PATHS.root}
          className="stack-x-2 items-center justify-between no-underline focus-visible:ring-0"
        >
          Dashboard
        </Link>
      </DropdownMenuItem>

      <DropdownMenuItem asChild>
        <Link
          to={ACCOUNT_PATHS.root}
          className="stack-x-2 items-center justify-between no-underline focus-visible:ring-0"
        >
          Settings
          <Icon className="text-ds-icon-primary h-4 w-4" aria-hidden>
            <IconUse id="settings-4-line" />
          </Icon>
        </Link>
      </DropdownMenuItem>

      <DropdownMenuItem asChild>
        <button
          onClick={() => {
            setOpen(true);
          }}
          className="stack-x-2 group w-full items-center justify-between text-left"
        >
          Search
          <Kbd className="group-hover:bg-ds-bg-foundation transition-colors">
            <span>⌘</span>
            <span>K</span>
          </Kbd>
        </button>
      </DropdownMenuItem>
    </>
  );
}

function ParticipantProfileMenuItems() {
  return (
    <>
      <DropdownMenuItem asChild>
        <Link
          to={P_DASHBOARD_PATHS.root}
          className="stack-x-2 items-center justify-between no-underline focus-visible:ring-0"
        >
          Dashboard
        </Link>
      </DropdownMenuItem>

      <DropdownMenuItem asChild>
        <Link
          to={P_ACCOUNT_PATHS.root}
          className="stack-x-2 items-center justify-between no-underline focus-visible:ring-0"
        >
          Settings
          <Icon className="text-ds-icon-primary h-4 w-4" aria-hidden>
            <IconUse id="settings-4-line" />
          </Icon>
        </Link>
      </DropdownMenuItem>
    </>
  );
}
