/**
 * @typedef {Object} VideoInfo
 * @property {string} type - The type of video (Vimeo/YouTube)
 * @property {string} id - The ID of the video
 */

/**
 * Parses video URL for type and id.
 *
 * @param {string} url The video URL
 * @returns {VideoInfo} The info related to the video
 * @credit https://gist.github.com/yangshun/9892961
 */
export const parseVideo = (url) => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  const matches = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/,
  );
  const info = {
    type: "",
    id: "",
  };

  if (matches?.length >= 7) {
    info.id = matches[6];

    if (matches[3]?.indexOf("youtu") > -1) {
      info.type = "youtube";
    } else if (matches[3]?.indexOf("vimeo") > -1) {
      info.type = "vimeo";
    } else {
      info.type = "unknown";
    }

    return info;
  }

  return {};
};
