type TItem<TData> = TData extends { pages: { items: (infer T)[] }[] } ? T : never;

export function createInfiniteDataTransform<TData extends { pages: { items: any[] }[] }, TTransformedItem>(
  data: TData,
  itemFn: (item: TItem<TData>, index: number) => TTransformedItem,
) {
  return {
    ...data,
    pages: data.pages.map((page) => {
      return {
        ...page,
        items: page.items.map(itemFn),
      };
    }),
  } as { pages: { items: TTransformedItem[] }[] } & Omit<TData, "pages">;
}
