import { useQuery } from "@tanstack/react-query";
import * as fuzzy from "fuzzy";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_QUERY_OPTIONS } from "shared/api/dashboard";
import { useUserProfile } from "shared/api/user/user";
import {
  Command,
  CommandDialog,
  CommandGroup,
  CommandGroupHeading,
  CommandInput,
  CommandItem,
  CommandList,
} from "shared/components/ds/Command";
import { Kbd } from "shared/components/ds/Kbd";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { MODULE_ICON } from "shared/constants/module-icon.constants";
import { useDebounce } from "shared/hooks/useDebounce";
import { focusRingStyles } from "shared/styles/focus";
import { isFromInput } from "shared/util/isFromInput.helpers";
import { getNavItems } from "shared/util/navigation.helpers";
import { JOB_PATHS, PARTICIPANT_PATHS, PARTNER_PATHS } from "team/constants/paths.constants";

export function CommandMenu() {
  const { data: user } = useUserProfile();
  const [open, setOpen] = useCommandMenu();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const params = React.useMemo(() => {
    return {
      ...(debouncedSearchTerm.length > 0 && { term: debouncedSearchTerm }),
    };
  }, [debouncedSearchTerm]);
  const { data, isPending } = useQuery({
    ...DASHBOARD_QUERY_OPTIONS.search({ params }),
    throwOnError: false,
    enabled: debouncedSearchTerm.length > 0,
    staleTime: 20 * 1000, // 20 seconds
  });
  const { jobs, partners, users } = data ?? {};

  const navigationItems = React.useMemo(() => {
    if (!user) return [];

    return fuzzy.filter<{ label: string; to: string }>(search, [...getNavItems(user.roles)[user.type]], {
      extract: (i) => i.label,
    });
  }, [search, user]);

  /** Focuses the input when the user presses cmd/ctrl+k */
  React.useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      /** Enables the user to open the command menu by pressing cmd/ctrl+k or "/" on their keyboard. */
      if (((e.metaKey || e.ctrlKey) && e.key === "k") || e.key === "/") {
        /** We want to skip opening the command menu if the user is typing in an editable html field. */
        if (isFromInput(e)) {
          return;
        }

        e.preventDefault();
        setOpen((open) => !open);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setOpen]);

  const runCommand = React.useCallback(
    (command: () => unknown) => {
      setOpen(false);
      command();
      setSearch("");
    },
    [setOpen],
  );

  const noResults = !isPending && !(users?.length || jobs?.length || partners?.length || navigationItems.length);

  return (
    <>
      {/** Mobile Trigger */}
      <button
        className={focusRingStyles({
          className:
            "hover:bg-ds-bg-weaker hover:ring-ds-stroke-tertiary grid h-10 w-10 place-items-center rounded ring-1 ring-transparent transition-all md:hidden",
        })}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Icon aria-hidden className="text-ds-text-primary h-4 w-4">
          <IconUse id="search-line-2" />
        </Icon>
        <span className="sr-only">Search...</span>
      </button>
      {/** Desktop Trigger */}
      <button
        onClick={() => {
          setOpen(true);
        }}
        className={focusRingStyles({
          className:
            "bg-ds-bg-weaker text-ds-text-primary placeholder:text-ds-text-tertiary hidden h-10 w-[240px] items-center justify-between rounded-lg px-3 text-sm transition-shadow md:flex",
        })}
      >
        <span className="stack-x-2">
          <Icon aria-hidden className="text-ds-text-tertiary h-[18px] w-[18px]">
            <IconUse id="search-line" />
          </Icon>
          <span className="block">Search...</span>
        </span>
        <Kbd className="bg-ds-bg-foundation shrink-0">
          <span>⌘</span>
          <span>K</span>
        </Kbd>
      </button>
      <CommandDialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open);

          setTimeout(() => {
            /** Wait to reset search until after close animation */
            setSearch("");
          }, 300);
        }}
      >
        <Command shouldFilter={false}>
          <CommandInput
            onValueChange={setSearch}
            placeholder="Type a command or search..."
            loading={isPending && debouncedSearchTerm.length > 0}
          />
          <CommandList>
            {noResults && <div className="text-ds-text-primary py-6 text-center text-sm">No results found.</div>}

            {navigationItems.length > 0 && (
              <CommandGroup heading={<CommandGroupHeading>Navigation</CommandGroupHeading>}>
                {navigationItems.map(({ original }) => {
                  return (
                    <CommandItem
                      key={`nav_${original.label}`}
                      value={original.label}
                      onSelect={() => {
                        runCommand(() => {
                          navigate(original.to);
                        });
                      }}
                    >
                      <Icon className="mr-2 h-4 w-4" aria-hidden>
                        <IconUse id="arrow-right-line" />
                      </Icon>
                      Go To {original.label}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {users?.length != null && users.length > 0 && (
              <CommandGroup heading={<CommandGroupHeading>Users</CommandGroupHeading>}>
                {users.map((user) => {
                  return (
                    <CommandItem
                      key={`user_${user.id}`}
                      value={user.id.toString()}
                      onSelect={() => {
                        runCommand(() => {
                          navigate(PARTICIPANT_PATHS.detail(user.id.toString()));
                        });
                      }}
                    >
                      <Icon className="mr-2 h-4 w-4" aria-hidden>
                        <IconUse id="user-line" />
                      </Icon>
                      {user.name}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {jobs?.length != null && jobs.length > 0 && (
              <CommandGroup heading={<CommandGroupHeading>Jobs</CommandGroupHeading>}>
                {jobs.map((job) => {
                  return (
                    <CommandItem
                      key={`job_${job.id}`}
                      value={job.id.toString()}
                      onSelect={() => {
                        runCommand(() => {
                          navigate(JOB_PATHS.detail(job.id.toString()));
                        });
                      }}
                    >
                      <Icon className="mr-2 h-4 w-4" aria-hidden>
                        <IconUse id={MODULE_ICON.roles} />
                      </Icon>
                      {job.name}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}

            {partners?.length != null && partners.length > 0 && (
              <CommandGroup heading={<CommandGroupHeading>Partner</CommandGroupHeading>}>
                {partners.map((partner) => {
                  return (
                    <CommandItem
                      key={`partner_${partner.id}`}
                      value={partner.id.toString()}
                      onSelect={() => {
                        runCommand(() => {
                          navigate(PARTNER_PATHS.detail(partner.id.toString()));
                        });
                      }}
                    >
                      <Icon className="mr-2 h-4 w-4" aria-hidden>
                        <IconUse id={MODULE_ICON.partners} />
                      </Icon>
                      {partner.name}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            )}
          </CommandList>
        </Command>
      </CommandDialog>
    </>
  );
}

const CommandMenuContext = React.createContext<[boolean, React.Dispatch<React.SetStateAction<boolean>>] | null>(null);

export function CommandMenuProvider({ children }: { children: React.ReactNode }) {
  const state = React.useState(false);

  return <CommandMenuContext.Provider value={state}>{children}</CommandMenuContext.Provider>;
}

export function useCommandMenu() {
  const context = React.useContext(CommandMenuContext);

  if (!context) {
    throw new Error("useCommandMenu must be used within a CommandMenuProvider");
  }

  return context;
}
