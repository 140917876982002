import { EditSavedExternalRoleDialog, EditSavedInternalRoleDialog } from "participant/components/EditSavedRoleDialog";
import { EditSavedRoleNotesDialog } from "participant/components/EditSavedRoleNotesDialog";
import { COMPANIES_PATHS } from "participant/constants/paths.constants";
import { useRemoveSavedRoleById } from "participant/hooks/useRemoveSavedRoleById";
import { Link } from "react-router-dom";
import { ExternalSavedRole, InternalSavedRole } from "shared/api/participants";
import { Button, ButtonIcon } from "shared/components/ds/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "shared/components/ds/DropdownMenu";
import { toast } from "shared/components/ds/Toast/Toast";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "shared/components/ds/Tooltip";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";

export function SavedRolesRowActions({ row }: { row: InternalSavedRole | ExternalSavedRole }) {
  const { id, type, notes } = row;

  switch (type) {
    case "internal": {
      return (
        <SavedRolesActionsLayout>
          {row.job.url && row.job?.url?.length > 0 && (
            <DropdownMenuItem asChild>
              <Link
                className="no-underline focus-visible:ring-0"
                to={row.job.url}
                target="_blank"
                rel="noreferrer noopener"
              >
                <Icon className="h-4 w-4" aria-hidden>
                  <IconUse id="external-link-line" />
                </Icon>
                View
              </Link>
            </DropdownMenuItem>
          )}
          {row.company?.id && (
            <DropdownMenuItem asChild>
              <Link className="no-underline focus-visible:ring-0" to={COMPANIES_PATHS.detail(row.company.id.toFixed())}>
                <Icon className="h-4 w-4" aria-hidden>
                  <IconUse id="building-line" />
                </Icon>
                Company
              </Link>
            </DropdownMenuItem>
          )}
          <EditSavedRoleNotesDialog notes={notes ?? ""} savedRoleId={id}>
            <DropdownMenuItem
              onSelect={(e) => {
                e.preventDefault();
              }}
            >
              <Icon className="h-4 w-4" aria-hidden>
                <IconUse id="file-text-line" />
              </Icon>
              Notes
            </DropdownMenuItem>
          </EditSavedRoleNotesDialog>
          <EditSavedInternalRoleDialog savedRoleId={id}>
            <DropdownMenuItem
              onSelect={(event) => {
                // Prevent the dropdown from closing when the delete button is clicked.
                event.preventDefault();
              }}
            >
              <Icon className="h-4 w-4" aria-hidden>
                <IconUse id="edit-line" />
              </Icon>
              Edit
            </DropdownMenuItem>
          </EditSavedInternalRoleDialog>
          <RemoveSavedRoleItem savedRoleId={id} />
        </SavedRolesActionsLayout>
      );
    }
    case "external": {
      return (
        <SavedRolesActionsLayout>
          <EditSavedRoleNotesDialog notes={notes ?? ""} savedRoleId={id}>
            <DropdownMenuItem
              onSelect={(e) => {
                e.preventDefault();
              }}
            >
              <Icon className="h-4 w-4" aria-hidden>
                <IconUse id="file-text-line" />
              </Icon>
              Notes
            </DropdownMenuItem>
          </EditSavedRoleNotesDialog>

          <EditSavedExternalRoleDialog savedRoleId={id}>
            <DropdownMenuItem
              onSelect={(event) => {
                // Prevent the dropdown from closing when the delete button is clicked.
                event.preventDefault();
              }}
            >
              <Icon className="h-4 w-4" aria-hidden>
                <IconUse id="edit-line" />
              </Icon>
              Edit
            </DropdownMenuItem>
          </EditSavedExternalRoleDialog>
          <RemoveSavedRoleItem savedRoleId={id} />
        </SavedRolesActionsLayout>
      );
    }

    default: {
      return null;
    }
  }
}

function SavedRolesActionsLayout({ children }: { children?: React.ReactNode }) {
  return (
    <DropdownMenu>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button className="ml-auto" svgOnly variant="ghost">
                <ButtonIcon>
                  <IconUse id="ri-more-fill" />
                </ButtonIcon>
                <span className="sr-only">Open menu</span>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom">Actions</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DropdownMenuContent align="end">
        <DropdownMenuGroup>
          <DropdownMenuLabel className="sr-only">List of actions for saved role</DropdownMenuLabel>
          {children}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function RemoveSavedRoleItem({ savedRoleId }: { savedRoleId: number }) {
  const mutation = useRemoveSavedRoleById({
    savedRoleId,
  });

  return (
    <DropdownMenuItem
      variant="destructive"
      disabled={mutation.isPending}
      onClick={() => {
        toast.promise(mutation.mutateAsync(), {
          loading: "Removing saved role...",
          success: "Successfully removed role",
          error: "Failed to remove role",
        });
      }}
    >
      <Icon className="h-4 w-4" aria-hidden>
        <IconUse id="delete-bin" />
      </Icon>
      Remove
    </DropdownMenuItem>
  );
}
