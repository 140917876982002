import { position } from "polished";
import PlayButton from "shared/components/PlayButton";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";

export const VideoButton = styled(PlayButton)`
  ${position("absolute", "-12px", null, null, "-12px")};

  ${screen.below(
    bp.mobile,
    `
    top: -20px;
    left: -17px;
  `,
  )}
`;
