import PropTypes from "prop-types";
import styled from "styled-components";
import Avatar from "./Avatar";

const AvatarFlex = styled.div`
  display: flex;
  align-items: start;
`;

const Image = styled(Avatar)`
  order: 0;
  flex: 0 0 var(--avatar-override-size, var(--size));
  width: var(--avatar-override-size, var(--size));
  line-height: var(--avatar-override-size, var(--size));
  margin-right: var(--avatar-override-gutter, var(--gutter));
`;

const Content = styled.div`
  order: 1;
`;

const AvatarTitle = ({ size, gutter, src, alt, children, fallback, fallbackFontSize, className }) => (
  <AvatarFlex className={className}>
    <Image
      src={src}
      alt={alt}
      size={size}
      style={{
        "--gutter": `${gutter}px`,
        "--size": `${size}px`,
      }}
      fallback={fallback}
      fallbackFontSize={fallbackFontSize}
    />

    <Content>{children}</Content>
  </AvatarFlex>
);

AvatarTitle.propTypes = {
  size: PropTypes.number,
  gutter: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
  fallbackFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default AvatarTitle;
