import { infiniteQueryOptions } from "@tanstack/react-query";
import api from "shared/api/api";
import { Params } from "shared/api/types/params";

export const LOCATIONS_KEYS = {
  all: ["locations"] as const,
  filter: (params: Params) => [...LOCATIONS_KEYS.all, "filter", params] as const,
  list: (params: Params) => [...LOCATIONS_KEYS.all, "filter", params] as const,
};

export const LOCATIONS_QUERY_OPTIONS = {
  infiniteLocationsList: (params: { [key: string]: unknown }) => {
    const { limit = 20 } = params ?? {};

    return infiniteQueryOptions({
      queryFn: async ({ pageParam = 1, signal }) => {
        const response = await api.get(`/locations`, {
          params: {
            ...params,
            limit,
            skip: (pageParam - 1) * (limit as number),
          },
          signal,
        });

        return response.data;
      },
      queryKey: LOCATIONS_KEYS.list({
        params: {
          ...params,
          limit,
        },
      }),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (lastPage.items.length < (limit as number)) {
          return undefined;
        }

        return lastPageParam + 1;
      },
    });
  },
};
