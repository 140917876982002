import { useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { DASHBOARD_QUERY_OPTIONS } from "shared/api/dashboard";
import { useSocket } from "shared/components/SocketProvider";

/** Custom hook that subscribes to a socket connection and invalidates React Query cache keys. */
export function useReactQuerySubscription() {
  const socket = useSocket();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (!socket) return;

    function handleNewNotifications() {
      queryClient.invalidateQueries({
        queryKey: DASHBOARD_QUERY_OPTIONS.unreadCount().queryKey,
      });
    }
    socket.on("newNotifications", handleNewNotifications);

    return () => {
      socket.off("newNotifications", handleNewNotifications);
    };
  }, [queryClient, socket]);
}
