import * as React from "react";
import { twMerge } from "tailwind-merge";

export const Kbd = React.forwardRef<HTMLElement, React.ComponentPropsWithoutRef<"kbd">>(
  ({ className, ...rest }, ref) => {
    return (
      <kbd
        className={twMerge(
          "border-ds-stroke-secondary bg-ds-bg-weaker text-muted-foreground pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border border-solid px-1.5 font-mono text-xs font-medium leading-none opacity-100 shadow-sm",
          className,
        )}
        ref={ref}
        {...rest}
      />
    );
  },
);
Kbd.displayName = "Kbd";
