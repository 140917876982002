import * as React from "react";
import { Icon, IconUse } from "shared/components/ds/icons/Icon";
import { IconLoader } from "shared/components/icons/IconLoader/IconLoader";
import { ExternalToast, Toaster as Sonner, toast as sonnerToast } from "sonner";
import styles from "./Toast.module.css";

export function Toaster(props: React.ComponentProps<typeof Sonner>) {
  return (
    <Sonner
      position="bottom-right"
      theme="light"
      className="font-primary m-0 list-none p-0 text-sm"
      style={
        {
          "--width": "420px",
        } as React.CSSProperties
      }
      icons={{
        success: (
          <Icon className="text-ds-green-base m-0">
            <IconUse id="ri-checkbox-circle-fill" />
          </Icon>
        ),
        error: (
          <Icon className="text-ds-red-base m-0">
            <IconUse id="ri-close-circle-fill" />
          </Icon>
        ),
        info: (
          <Icon className="text-ds-text-primary m-0">
            <IconUse id="information-fill" />
          </Icon>
        ),
        warning: (
          <Icon className="text-ds-yellow-base m-0">
            <IconUse id="error-warning-fill" />
          </Icon>
        ),
        loading: <IconLoader animate className="h-4 w-4" />,
      }}
      duration={5000}
      toastOptions={{
        unstyled: true,
        classNames: {
          toast: `${styles.toast} pl-4 w-full bg-ds-bg-foundation border shadow-lg border-ds-stroke-tertiary rounded-xl`,
          closeButton: "bg-ds-bg-foundation border-ds-stroke-tertiary border hover:bg-ds-bg-weaker transition-colors",
          description: "text-ds-text-secondary",
          content: "py-4 pr-3 [grid-area:content]",
          icon: "m-0 mr-3 w-5 h-5 grid place-items-center mt-4 [grid-area:icon]",
          title: "font-medium",
          actionButton: `min-h-[38px] w-full [grid-area:action] min-w-[64px] px-4 text-ds-text-primary font-medium hover:bg-ds-bg-weaker transition-colors border-l border-ds-stroke-tertiary ${styles.actionButton}`,
          cancelButton:
            "min-h-[38px] w-full [grid-area:action] border-ds-stroke-teriary min-w-[64px] px-4 text-ds-text-secondary hover:bg-ds-bg-weaker transition-colors border-l",
        },
      }}
      {...props}
    />
  );
}

function catastrophic(message: string | React.ReactNode, data?: ExternalToast) {
  return sonnerToast.error(message, {
    ...data,
    important: true,
    className: "bg-ds-red-base border-transparent",
    classNames: {
      ...data?.classNames,
      title: "text-ds-text-inverse",
      description: "!text-ds-text-inverse",
      actionButton: "!text-ds-text-inverse hover:!text-ds-red-base !border-ds-stroke-inverse",
      cancelButton: "!text-ds-text-inverse hover:!text-ds-red-base !border-ds-stroke-inverse",
      icon: "!text-ds-text-inverse",
    },
    icon: (
      <Icon className="text-ds-text-inverse">
        <IconUse id="ri-close-circle-fill" />
      </Icon>
    ),
  });
}

type SonnerToast = typeof sonnerToast;
interface ExtendedSonnerToast extends SonnerToast {
  catastrophic: (message: string | React.ReactNode, data?: ExternalToast) => string | number;
}

const toast = sonnerToast as ExtendedSonnerToast;
toast.catastrophic = catastrophic;
export { toast };
