import { QueryClient } from "@tanstack/react-query";
import * as React from "react";
import { LoaderFunctionArgs } from "react-router-dom";
import { JOBS_QUERY_OPTIONS } from "shared/api/jobs";
import { LoadingContainer } from "shared/components/ds/Spinner";
import { getTableParamsFromRequest } from "shared/util/tableHelpers";
import {
  ListNotCurrentProcessTable,
  ListNotCurrentProcessTableContainer,
  NOT_IN_PROCESS_TABLE_PARAMS_KEY,
} from "team/views/JobInterviewsView/components/ListNotCurrentProcess";
import {
  IN_PROCESS_TABLE_PARAMS_KEY,
  ListProcessTable,
  ListProcessTableContainer,
} from "team/views/JobInterviewsView/components/ListProcessTable";

export async function jobInterviewsLoader(queryClient: QueryClient, { request, params }: LoaderFunctionArgs) {
  try {
    const { jobId } = params ?? {};

    if (!jobId) {
      throw new Error("Job ID is required to load interviews");
    }

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.processList(
        jobId,
        getTableParamsFromRequest({
          request,
          key: IN_PROCESS_TABLE_PARAMS_KEY,
          filters: {
            current: true,
          },
        }),
      ),
    );

    queryClient.ensureQueryData(
      JOBS_QUERY_OPTIONS.processList(
        jobId,
        getTableParamsFromRequest({
          request,
          key: NOT_IN_PROCESS_TABLE_PARAMS_KEY,
          filters: {
            current: false,
          },
        }),
      ),
    );

    return null;
  } catch (error) {
    return null;
  }
}

export function JobInterviewsView() {
  return (
    <div className="stack-y-12">
      <ListProcessTableContainer>
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <ListProcessTable />
        </React.Suspense>
      </ListProcessTableContainer>
      <ListNotCurrentProcessTableContainer>
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <ListNotCurrentProcessTable />
        </React.Suspense>
      </ListNotCurrentProcessTableContainer>
    </div>
  );
}
