import create from "zustand";

/** Global store to manage notifications and tasks. The task count is used to trigger a mutate on the individual pages. */
type NotificationStore = {
  taskCount: number;
  notificationCount: number;
  incrementTaskCount: () => void;
  incrementNotificationCount: () => void;
};
const useNotificationStore = create<NotificationStore>((set) => ({
  taskCount: 0,
  notificationCount: 0,
  incrementTaskCount: () => {
    return set((state) => ({ taskCount: state.taskCount + 1 }));
  },
  incrementNotificationCount: () => {
    return set((state) => {
      return {
        notificationCount: state.notificationCount + 1,
      };
    });
  },
}));

export default useNotificationStore;
